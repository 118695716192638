import DOMPurify from "dompurify";
import moment from "moment";
import RAFAddress from "../../components/shared/RAFAddress/RAFAddress";
import RAFAddress1 from "../../components/shared/RAFAddress/RAFAddress1";
import RAFEmail1 from "../../components/shared/RAFEmail/RAFEmail1";
import RAFPhone1 from "../../components/shared/RAFPhone/RAFPhone1";
import { getCreateComponent } from "../../RAFComponents/helpers/RAFMenuHelper";
import {
  convertUTCDateToLocalTimezone,
  isArray,
  isDate,
  isNotEmptyArray,
  isNotNullAndUndefined,
  IsNotNullOrWhiteSpace,
  isNullOrUndefined,
  IsNullOrWhiteSpace,
  isNumber,
  setZeroHours,
} from "../../RAFComponents/helpers/utils";
import RAFCheckBox from "../../RAFComponents/Inputs/RAFCheckBox";
import RAFChoiceOption from "../../RAFComponents/Inputs/RAFChoiceOption";
import RAFComboBoxCC from "../../RAFComponents/Inputs/RAFComboBoxCC";
import RAFDateTimePicker from "../../RAFComponents/Inputs/RAFDateTimePicker";
import RAFDropdownCC from "../../RAFComponents/Inputs/RAFDropdownCC";
import RAFEmailTextBox from "../../RAFComponents/Inputs/RAFEmailTextBox";
import RAFFileAttachment from "../../RAFComponents/Inputs/RAFFileAttachment";
import RAFHtmlEditor from "../../RAFComponents/Inputs/RAFHtmlEditor";
import RAFImageInput from "../../RAFComponents/Inputs/RAFImageInput";
import RAFLookupAutoCompleteCC from "../../RAFComponents/Inputs/RAFLookupAutoCompleteCC";
import RAFLookupCC from "../../RAFComponents/Inputs/RAFLookupCC";
import RAFManageTagItems from "../../RAFComponents/Inputs/RAFManageTagItems";
import RAFMultiSelectDropdown from "../../RAFComponents/Inputs/RAFMultiSelectDropdown";
import RAFMultiSelectLookupCC from "../../RAFComponents/Inputs/RAFMultiSelectLookupCC";
import RAFNumber from "../../RAFComponents/Inputs/RAFNumber";
import RAFPassword from "../../RAFComponents/Inputs/RAFPassword";
import RAFRadioButtonList from "../../RAFComponents/Inputs/RAFRadioButtonList";
import RAFRecurrenceEditor from "../../RAFComponents/Inputs/RAFRecurrenceEditor";
import RAFSignaturePad from "../../RAFComponents/Inputs/RAFSignaturePad";
import RAFStartRatingInput from "../../RAFComponents/Inputs/RAFStartRatingInput";
import RAFTagInput from "../../RAFComponents/Inputs/RAFTagInput";
import RAFTextArea from "../../RAFComponents/Inputs/RAFTextArea";
import RAFTextBox from "../../RAFComponents/Inputs/RAFTextBox";
import RAFVideoInput from "../../RAFComponents/Inputs/RAFVideoInput";
import { EvaluateRAFFormRules } from "../../RAFComponents/Inputs/RFFUtils";
//import { RAFFormContext } from "../../RAFComponents/Inputs/RFFUtils";
import { RAFActionStatus } from "../../constants/Common/Constants";
import { RAFEntityName } from "../../constants/Common/EntityConstants";
import { getFieldCustomFilter } from "../../RAFComponents/helpers/AppHelper";
import RAFAutoCompleteMUI from "../../RAFComponents/Inputs/RAFAutoCompleteMUI";
import RAFCustomTextRow from "../../RAFComponents/Inputs/RAFCustomTextRow";
import RAFDatePickerMUI from "../../RAFComponents/Inputs/RAFDatePickerMUI";
import RAFLatitudeLongitude from "../../RAFComponents/Inputs/RAFLatitudeLongitude";
import RAFLookUpMUI from "../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFRRuleInput from "../../RAFComponents/Inputs/RAFRRuleInput";
import RAFSentimentToggle from "../../RAFComponents/Inputs/RAFSentimentToggle";
import RAFStarRating from "../../RAFComponents/Inputs/RAFStarRating";
import { AttributeRelatedList } from "../../RAFComponents/models/Common/AttributeRelatedList";
import {
  FormLibraryRow,
  RAFRuleAction,
} from "../../RAFComponents/models/Common/FormLibraryRow";
import {
  ImageSettings,
  QueryAttributeJM,
  RatingSettings,
  ValueJson,
  VideoSettings,
} from "../../RAFComponents/models/Common/QueryAttributeJM";
import {
  RAFDataType,
  RAFUIType,
} from "../../RAFComponents/models/Common/RAFDataType";
import { ILookupRow } from "../../RAFComponents/models/CompositeTypes/ILookupRow";
import RAFDetailField from "../../RAFComponents/Navigation/RAFDetailField";
import { RAFDetailFieldEditable } from "../../RAFComponents/Navigation/RAFDetailFieldEditable";
import { flattenFormComponents } from "../../RAFMaster/helpers/RMutils";
import { RAFDataModelRow } from "../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import RAFDocFileUploader from "../ActiveContacts/Document/DocumentInput/RAFDocFileUploader";
import RAFDocFolderInput from "../ActiveContacts/Document/DocumentInput/RAFDocFolderInput";
import { ContentLibraryRow } from "../ActiveContacts/Document/Library/ContentLibraryRow";
import RAFRecordCategoryInput from "../ActiveContacts/RecordCategory/RecordCategoryInput/RAFRecordCategoryInput";
import CQIRegisterCreateComponent from "../CareESIO/CQIRegister/CQIRegisterCreateComponent";
import {
  AddressRow,
  AddressType,
} from "../../components/shared/RAFAddress/AddressRow";

export function getUrlByRelatedEntites(relatedEntities?: string) {
  let url;
  if (isNotNullAndUndefined(relatedEntities)) {
    const firstItem = relatedEntities;
    url = isNotNullAndUndefined(firstItem)
      ? firstItem.replace(/_/g, "").replace(/\s/g, "") + "/Lookup"
      : null;
  }
  if (isNotNullAndUndefined(url)) {
    return url;
  } else {
    return null;
  }
}

// function getFormElement1(
//   entityName?: string,
//   attributeRelatedList?: AttributeRelatedList,
//   queryAttributes?: QueryAttributeJM[],
//   attributeUID?: string,
//   fieldPrefix?: string,
//   validateField?: boolean,
//   showLabel?: boolean
// ) {
//   let retVal = <div></div>;
//   const attrib =
//     attributeRelatedList &&
//     attributeRelatedList.Attributes &&
//     attributeRelatedList.Attributes.find((x) => x.UID === attributeUID);
//   let compName: string = attrib.Name;
//   //console.log('attributeRelatedList', attributeRelatedList, compName, queryAttributes);
//   const queryAttribs =
//     queryAttributes &&
//     queryAttributes.filter((x) => x.AttributeName === attrib.Name);

//   if (isNotNullAndUndefined(queryAttribs) && queryAttribs.length > 0) {
//     if (attrib.DataType === RAFDataType.Form) {
//       if (attrib.IsQueryable && attrib.Visible === true) {
//         retVal = (
//           <div>
//             {queryAttribs.map((item, i) => {
//               if (item.Visible === true) {
//                 return (
//                   <div key={item.AttributeUID}>
//                     {getElement(
//                       entityName,
//                       item.AttributeUID,
//                       item.DataType,
//                       item.PropertyName,
//                       item.DisplayName,
//                       validateField,
//                       item.IsRequired,
//                       showLabel,
//                       !item.IsEditable,
//                       item.UIType,
//                       item.IsMultiSelect,
//                       item.ValueJson,
//                       item.RelatedEntities
//                     )}
//                   </div>
//                 );
//               } else {
//                 return (retVal = <div></div>);
//               }
//             })}
//           </div>
//         );
//         return retVal;
//       } else {
//         retVal = (
//           <RAFAttributeRelatedListProvider moduleName={attrib.RelatedEntities}>
//             <RAFPageLayoutProvider>
//               <RAFPageLayout />
//             </RAFPageLayoutProvider>
//           </RAFAttributeRelatedListProvider>
//         );
//         return retVal;
//       }
//     } else if (attrib.DataType === RAFDataType.Lookup) {
//       for (var i = 0; i < queryAttribs.length; i++) {
//         if (
//           queryAttribs[i].ShowInSelect === true &&
//           queryAttribs[i].ShowInWhere === true
//         ) {
//           compName = queryAttribs[i].PropertyName;
//         }
//       }
//     } else {
//       compName = queryAttribs[0].PropertyName;
//     }
//   }
//   const isRequired = attrib.AttributeSettings
//     ? attrib.AttributeSettings.IsRequired
//     : false;
//   const isDisabled = attrib.AttributeSettings
//     ? attrib.AttributeSettings.IsReadonly
//     : false;
//   if (isNullOrUndefined(showLabel)) {
//     showLabel = true;
//   }
//   let showComponet = true;
//   if (validateField === true) {
//     if (attrib.Visible === false) {
//       showComponet = false;
//     }
//   }

//   if (!IsNullOrWhiteSpace(fieldPrefix)) {
//     compName = `${fieldPrefix}.${compName}`;
//   }

//   if (showComponet) {
//     retVal = getElement(
//       entityName,
//       attrib.UID,
//       attrib.DataType,
//       compName,
//       attrib.DisplayName,
//       validateField,
//       isRequired,
//       showLabel,
//       isDisabled,
//       attrib.AttributeSettings.UIType,
//       attrib.Multiselect,
//       attrib.ValueJson,
//       attrib.RelatedEntities
//     );
//   }
//   return retVal;
// }

export function getFormElement(
  entityName: string,
  queryAttributes: QueryAttributeJM[],
  attributeUID: string,
  IsHidden: boolean,
  defaultValue: any,
  placeholder: string,
  fieldPrefix: string,
  validateField: boolean,
  showLabel: boolean,
  mode?: "Edit" | "Details" | "Create"
) {
  let retVal = <div></div>;
  const queryAttrib =
    queryAttributes &&
    queryAttributes.find(
      (x) =>
        (x.AttributeUID === attributeUID &&
          x.Visible === true &&
          x.ShowInSelect === true &&
          x.ShowInWhere === true) ||
        (x.AttributeUID === attributeUID &&
          x.FieldName === "Parent" &&
          entityName === RAFEntityName.ContentLibrary)
    );
  if (isNullOrUndefined(queryAttrib)) {
    return retVal;
  }

  return getFormElementByAttribute(
    entityName,
    queryAttrib,
    attributeUID,
    IsHidden,
    defaultValue,
    placeholder,
    fieldPrefix,
    validateField,
    showLabel,
    mode
  );
}

export function getFormElementByAttribute(
  entityName: string,
  queryAttrib: QueryAttributeJM,
  attributeUID: string,
  IsHidden: boolean,
  defaultValue: any,
  placeholder: string,
  fieldPrefix: string,
  validateField: boolean,
  showLabel: boolean,
  mode?: "Edit" | "Details" | "Create"
) {
  let retVal = <div></div>;
  if (
    isNullOrUndefined(queryAttrib) ||
    IsNullOrWhiteSpace(queryAttrib.PropertyName)
  ) {
    return retVal;
  }
  let compName: string = queryAttrib.PropertyName;
  const isRequired = isNotNullAndUndefined(queryAttrib.IsRequired)
    ? queryAttrib.IsRequired
    : false;
  const isDisabled =
    isNotNullAndUndefined(queryAttrib.IsEditable) &&
    queryAttrib.IsEditable === true
      ? false
      : mode === "Details"
      ? false
      : true;
  if (isNullOrUndefined(showLabel)) {
    showLabel = true;
  }
  let showComponet = true;
  //if (validateField === true) {
  if (
    isNotNullAndUndefined(queryAttrib.Visible) &&
    queryAttrib.Visible === false
  ) {
    showComponet = false;
  }
  //}
  if (IsHidden === true) {
    showComponet = false;
  }

  if (!IsNullOrWhiteSpace(fieldPrefix)) {
    compName = `${fieldPrefix}.${compName}`;
  }
  if (showComponet) {
    retVal = getElement(
      entityName,
      queryAttrib.AttributeUID,
      queryAttrib.DataType,
      compName,
      queryAttrib.DisplayName,
      null,
      validateField,
      isRequired,
      showLabel,
      isDisabled,
      queryAttrib.UIType,
      queryAttrib.IsMultiSelect,
      queryAttrib.ValueJson,
      queryAttrib.RelatedEntities,
      defaultValue,
      queryAttrib.UISettings,
      mode,
      placeholder,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }
  return retVal;
}

function onRAFFormOnchange(values, ruleActions: RAFRuleAction[]) {
  if (isNotNullAndUndefined(ruleActions))
    EvaluateRAFFormRules(values, ruleActions);
  return;
}

export const getInitialValueForAttribute = (
  attribute: QueryAttributeJM,
  component?: RAFDataModelRow
) => {
  let dataType;
  let uiType;
  let defaultValue;
  let isMultiselect;

  if (isNotNullAndUndefined(attribute)) {
    uiType = attribute.UIType;
    dataType = attribute.DataType;
    isMultiselect = attribute.IsMultiSelect;
    defaultValue = isNotNullAndUndefined(attribute.DefaultValue)
      ? attribute.DefaultValue
      : null;
  } else if (isNotNullAndUndefined(component)) {
    dataType = component.DataType;
    defaultValue = isNotNullAndUndefined(component.AttributeSettings)
      ? component.AttributeSettings.DefaultValue
      : null;
    uiType = isNotNullAndUndefined(component.AttributeSettings)
      ? component.AttributeSettings.UIType
      : null;
    isMultiselect = isNotNullAndUndefined(component.AttributeSettings)
      ? component.Multiselect
      : null;
  }

  let outValue;
  if (isNotNullAndUndefined(defaultValue) && isNotNullAndUndefined(dataType)) {
    if (dataType === RAFDataType.Text || dataType === RAFDataType.RichText) {
      outValue = defaultValue;
    } else if (
      dataType === RAFDataType.Date ||
      dataType === RAFDataType.DateTime
    ) {
      let dateVal: Date = getDefaultDateFieldValue(defaultValue);
      return dateVal;
    } else if (dataType === RAFDataType.Number) {
      outValue = isNumber(Number(defaultValue)) ? Number(defaultValue) : null;
    } else if (dataType === RAFDataType.Boolean) {
      outValue = defaultValue;
    } else if (dataType === RAFDataType.Dropdown) {
      if (isMultiselect) {
        if (isNotEmptyArray(defaultValue)) {
          //outValue = outValue;
          outValue = null;
        } else {
          outValue = null;
        }
      } else {
        outValue = defaultValue;
      }
    } else {
      //console.log('dataType', dataType, uiType, defaultValue);
      outValue = defaultValue;
    }
  }

  return outValue;
};

const getDefaultDateFieldValue = (defaultValue) => {
  if (isNotNullAndUndefined(defaultValue)) {
    let dateVal: Date = new Date();
    if (defaultValue.toLowerCase() === "{{today}}") {
      dateVal = setZeroHours(moment().toDate());
    } else if (defaultValue.toLowerCase() === "{{tomorrow}}") {
      dateVal = setZeroHours(moment().add(1, "days").toDate());
    } else if (defaultValue.toLowerCase() === "{{yesterday}}") {
      dateVal = setZeroHours(moment().subtract(1, "days").toDate());
    } else if (isDate(defaultValue)) dateVal = defaultValue;
    return dateVal;
  }
  return null;
};

export const getInitialValueForForm = (
  allattribute: QueryAttributeJM[],
  initalObject
) => {
  let outValue = isNotNullAndUndefined(initalObject) ? initalObject : {};
  isNotEmptyArray(allattribute) &&
    allattribute.forEach((item) => {
      if (IsNullOrWhiteSpace(outValue[item.FieldName])) {
        outValue[item.FieldName] = getInitialValueForAttribute(item);
      }
    });
  return outValue;
};

export function getElement(
  entityName?: string,
  attribUID?: string,
  dataType?: string,
  compName?: string,
  displayName?: string,
  fieldTitle?: string,
  validateField?: boolean,
  isRequired?: boolean,
  showLabel?: boolean,
  isDisabled?: boolean,
  uiType?: string,
  multiselect?: boolean,
  valueJson?: ValueJson[],
  relatedEntities?: string,
  defaultValue?: any,
  uiSettings?: any,
  mode?: "Edit" | "Details" | "Create",
  placeholder?: string,
  defaultLabel?: string,
  formDataFieldName?: string,
  fieldDescription?: string,
  isPreviewMode?: boolean,
  formRules?: RAFRuleAction[],
  formValues?: any,
  descriptionAsLabel?: boolean,
  titleLocation?: string,
  formLibrary?: FormLibraryRow,
  fieldName?: string
) {
  let retVal;
  if (
    isNotNullAndUndefined(formLibrary) &&
    isNotNullAndUndefined(formLibrary.DataModel) &&
    isNotEmptyArray(formLibrary.DataModel.Components)
  ) {
    let flattenFormComponent: RAFDataModelRow[] = flattenFormComponents(
      formLibrary.DataModel.Components
    );
    let selectedFormComponent: RAFDataModelRow = flattenFormComponent.find(
      (x) => x.UID === attribUID
    );
    if (isNotNullAndUndefined(selectedFormComponent)) {
      formRules = selectedFormComponent.FormRules;
    }
    if (compName === "CareShiftType") {
      if (isNotEmptyArray(valueJson)) {
        valueJson = valueJson.filter(
          (x) =>
            x.Name !== "complete_shift" &&
            x.Name !== "start_shift" &&
            x.Name !== "to_do" &&
            x.Name !== "appointment"
        );
      }
    }

    if (
      (formLibrary.Entity === "incident" ||
        formLibrary.Entity === "complaint_register") &&
      compName === "Status"
    ) {
      if (isNotEmptyArray(valueJson)) {
        valueJson = valueJson.filter(
          (x) =>
            x.DisplayName === RAFActionStatus.InProgress ||
            x.DisplayName === RAFActionStatus.Resolved
        );
      }
    }

    if (formLibrary.Entity === "risk" && compName === "Status") {
      if (isNotEmptyArray(valueJson)) {
        valueJson = valueJson.filter(
          (x) =>
            x.DisplayName === RAFActionStatus.InProgress ||
            x.DisplayName === RAFActionStatus.Done
        );
      }
    }

    if (formLibrary.Entity === "medication_log" && compName === "Status") {
      if (isNotEmptyArray(valueJson)) {
        valueJson = valueJson.filter(
          (x) =>
            x.DisplayName === RAFActionStatus.Taken ||
            x.DisplayName === RAFActionStatus.PartiallyTaken ||
            x.DisplayName === RAFActionStatus.NotTaken ||
            x.DisplayName === RAFActionStatus.Refused
        );
      }
    }
  }
  if (
    entityName === RAFEntityName.ContentLibrary &&
    relatedEntities === RAFEntityName.RecordCategory &&
    mode === "Create"
  ) {
    retVal = (
      <RAFRecordCategoryInput
        key={compName}
        field={
          isNotNullAndUndefined(formDataFieldName)
            ? formDataFieldName + "." + compName
            : compName
        }
        label="Choose record category"
        url={"RecordCategory/Lookup"}
        placeholder="Choose record category"
        showClearButton
      />
    );
  } else if (
    entityName === RAFEntityName.ContentLibrary &&
    compName === "Parent" &&
    mode === "Create"
  ) {
    let rootDocFolderRow = new ContentLibraryRow();
    (rootDocFolderRow.UID = "dde78fec-ae48-4036-80d0-64c08184c10f"),
      (rootDocFolderRow.DisplayName = "Documents"),
      (rootDocFolderRow.FileName = "Documents"),
      (rootDocFolderRow.DocumentType = "Folder"),
      (rootDocFolderRow.Parent = null),
      (rootDocFolderRow.ParentUID = "10eab98c-20b0-4c5b-9b7f-139478b0c644"),
      (rootDocFolderRow.IsExternal = true),
      (rootDocFolderRow.IsSystem = true),
      (rootDocFolderRow.Entity = "Content Library"),
      (rootDocFolderRow.CurrentStatus = "Published"),
      (rootDocFolderRow.Portal = "Dev"),
      (rootDocFolderRow.PortalUID = "47735ef1-644e-450d-be48-c7f83c56c9e3"),
      (rootDocFolderRow.TenantUID = "63473f57-0b38-4d96-b323-8a11a1cca21e"),
      (retVal = (
        <RAFDocFolderInput
          key={compName}
          field={
            isNotNullAndUndefined(formDataFieldName)
              ? formDataFieldName + "." + compName
              : compName
          }
          label="Choose file location"
          url="ContentLibrary/Lookup"
          moduleName={RAFEntityName.ContentLibrary}
          rootDocFolderRow={{
            UID: "dde78fec-ae48-4036-80d0-64c08184c10f",
            DisplayName: "Documents",
            FileName: "Documents",
            DocumentType: "Folder",
            Parent: null,
            ParentUID: "10eab98c-20b0-4c5b-9b7f-139478b0c644",
            IsExternal: true,
            IsSystem: true,
            Entity: "Content Library",
            CurrentStatus: "Published",
          }}
          required
          titleLocation={titleLocation}
        />
      ));
  } else if (
    entityName === RAFEntityName.ContentLibrary &&
    (relatedEntities === RAFEntityName.RecordCategory ||
      compName === "Parent") &&
    mode !== "Create"
  ) {
    retVal = <></>;
  } else {
    const fieldItemLabel = IsNotNullOrWhiteSpace(fieldTitle)
      ? fieldTitle
      : defaultLabel;
    //console.log('fieldItemLabel', fieldItemLabel, '---fieldTitle---', fieldTitle, '---defaultLabel---', defaultLabel);
    switch (dataType) {
      case RAFDataType.Text: {
        if (compName === "TagsListJson") {
          retVal = (
            <RAFTagInput
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              placeholder={
                isNotNullAndUndefined(placeholder) ? placeholder : null
              }
              required={isRequired}
              showLabel={showLabel}
              disabled={isDisabled}
              showAddBtn={isDisabled === true ? false : true}
            />
          );
        } else if (
          compName === "Address1" &&
          formLibrary.Entity === "care_recipient"
        ) {
          retVal = (
            <RAFAddress
              field1="Address1"
              field2="Address2"
              field3="City"
              field4="State"
              field5="Country"
              field6="PostalCode"
              //required={isRequired}
              //showLabel={showLabel}
              //disabled={isDisabled}
            />
          );
        } else if (uiType === RAFUIType.HtmlEditor) {
          retVal = (
            <RAFHtmlEditor
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              placeholder={
                isNotNullAndUndefined(placeholder) ? placeholder : null
              }
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              htmlEditorHeight="100%"
              fullHeight={false}
              formGroupClassName="mb-0"
            />
          );
        } else if (uiType === RAFUIType.Address) {
          retVal = (
            <div>
              <div className="col-12 d-flex">
                <label className="form-label">Address</label>
              </div>
              <RAFAddress
                field1="AddressLine1"
                field2="AddressLine2"
                field3="AddressCity"
                field4="AddressState"
                field5="AddressCountry"
                field6="AddressPostalCode"
              />
              {/*<RAFAutoFillAddress
                field1="AddressLine1"
                field2="AddressLine2"
                field3="AddressCity"
                field4="AddressState"
                field5="AddressCountry"
                field6="AddressPostalCode"
              />*/}
            </div>
          );
        } else if (uiType === RAFDataType.Signature) {
          retVal = (
            <RAFSignaturePad
              required={isRequired}
              showLabel={showLabel}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              formGroupClassName="mb-0"
              titleLocation={titleLocation}
            />
          );
        } else if (uiType === RAFUIType.Multiline) {
          retVal = (
            <RAFTextArea
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              placeholder={
                isNotNullAndUndefined(placeholder) ? placeholder : null
              }
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
            />
          );
        } else if (uiType === RAFDataType.Attachment) {
          retVal = (
            <RAFDocFileUploader
              required={isRequired}
              showLabel={showLabel}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              disabled={isPreviewMode}
              formGroupClassName="mb-0"
            />
          );
        } else if (uiType === RAFUIType.Autosuggestion) {
          let url = "StandardizedLibrary/Lookup";
          let createForm = getCreateComponent(
            RAFEntityName.StandardizedLibrary
          );
          if (entityName === "care_intervention_support") {
            retVal = (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <label className={"form-label required"}>
                      {isNotNullAndUndefined(fieldItemLabel)
                        ? fieldItemLabel
                        : compName}
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="mb-2">
                      <label className={"form-label"}>Must-do</label>
                    </div>
                    <RAFCheckBox
                      field={`IsRequired`}
                      label="Must-do"
                      showLabel={false}
                      validate={false}
                      labelPosition="right"
                      inputFieldClassName="col-auto"
                      labelClassName="col"
                      formGroupClassName="mb-2"
                      rowClassName="row g-2 align-items-center"
                      uitype={"switch"}
                      hideRequiredMessage
                    ></RAFCheckBox>
                  </div>
                </div>
                {/* <RAFLookUpMUI  */}
                <RAFLookUpMUI
                  key={compName}
                  field={
                    isNotNullAndUndefined(formDataFieldName)
                      ? formDataFieldName + "." + compName
                      : compName
                  }
                  {...(isNotNullAndUndefined(fieldItemLabel)
                    ? { label: fieldItemLabel }
                    : {})}
                  {...(isNotNullAndUndefined(validateField)
                    ? { validate: validateField }
                    : {})}
                  {...(isNotNullAndUndefined(fieldDescription)
                    ? { description: fieldDescription }
                    : {})}
                  {...(isNotNullAndUndefined(descriptionAsLabel)
                    ? { descriptionAsLabel: descriptionAsLabel }
                    : {})}
                  placeholder={
                    isNotNullAndUndefined(placeholder) ? placeholder : null
                  }
                  //type={ILookupRow}
                  required={isRequired}
                  showLabel={false}
                  //initialValue={defaultValue}
                  // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                  //   ? { initialValue: defaultValue }
                  //   : {})}
                  disabled={isDisabled}
                  url={isNotNullAndUndefined(url) ? url : null}
                  moduleName={RAFEntityName.StandardizedLibrary}
                  showCreateButton
                  formGroupClassName={"mb-0"}
                  createform={createForm}
                  //createformdefaultfield={defaultField}
                  //selectedField={defaultField}
                  SearchCreateOptionMode={"Footer"}
                  onChanged={(e) => {
                    onRAFFormOnchange(formValues, formRules);
                  }}
                  titleLocation={titleLocation}
                  customFilter={getFieldCustomFilter(
                    entityName,
                    formValues,
                    RAFUIType.Autosuggestion
                  )}
                />
              </div>
            );
          } else {
            retVal = (
              <RAFAutoCompleteMUI
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                //type={ILookupRow}
                required={isRequired}
                showLabel={showLabel}
                ////initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                url={isNotNullAndUndefined(url) ? url : null}
                moduleName={RAFEntityName.StandardizedLibrary}
                //showCreateButton
                formGroupClassName={"mb-0"}
                createform={createForm}
                //createformdefaultfield={defaultField}
                //selectedField={defaultField}
                SearchCreateOptionMode={"Footer"}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
                customFilter={getFieldCustomFilter(
                  entityName,
                  formValues,
                  RAFUIType.Autosuggestion
                )}
              />
            );
            let retVal1 = (
              <RAFLookupAutoCompleteCC
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                //type={ILookupRow}
                required={isRequired}
                showLabel={showLabel}
                ////initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                url={isNotNullAndUndefined(url) ? url : null}
                moduleName={RAFEntityName.StandardizedLibrary}
                //showCreateButton
                formGroupClassName={"mb-0"}
                createform={createForm}
                //createformdefaultfield={defaultField}
                //selectedField={defaultField}
                SearchCreateOptionMode={"Footer"}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
                customFilter={getFieldCustomFilter(
                  entityName,
                  formValues,
                  RAFUIType.Autosuggestion
                )}
              />
            );
          }
        } else if (uiType === RAFUIType.RRScheduler) {
          retVal = (
            <RAFRecurrenceEditor
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              placeholder={
                isNotNullAndUndefined(placeholder) ? placeholder : null
              }
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              titleLocation={titleLocation}
            />
          );
        } else if (uiType === RAFUIType.RRule) {
          retVal = (
            <RAFRRuleInput
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              placeholder={
                isNotNullAndUndefined(placeholder) ? placeholder : null
              }
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              titleLocation={titleLocation}
            />
          );
        } else {
          retVal = (
            <RAFTextBox
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              placeholder={
                isNotNullAndUndefined(placeholder) ? placeholder : null
              }
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              titleLocation={titleLocation}
            />
          );
        }
        break;
      }
      case RAFDataType.AutoNumber: {
        retVal = (
          <RAFTextBox
            key={compName}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            {...(isNotNullAndUndefined(validateField)
              ? { validate: validateField }
              : {})}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            placeholder={
              isNotNullAndUndefined(placeholder) ? placeholder : null
            }
            required={isRequired}
            showLabel={showLabel}
            ////initialValue={defaultValue}
            // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
            //   ? { initialValue: defaultValue }
            //   : {})}
            disabled
            formGroupClassName={"mb-0"}
          />
        );
        break;
      }
      case RAFDataType.Formula: {
        retVal = (
          <RAFTextBox
            key={compName}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            {...(isNotNullAndUndefined(validateField)
              ? { validate: validateField }
              : {})}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            required={isRequired}
            placeholder={
              isNotNullAndUndefined(placeholder) ? placeholder : null
            }
            showLabel={showLabel}
            ////initialValue={defaultValue}
            // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
            //   ? { initialValue: defaultValue }
            //   : {})}
            disabled
            formGroupClassName={"mb-0"}
          />
        );
        break;
      }
      case RAFDataType.Number: {
        if (uiType === RAFUIType.StarRating) {
          let ratingSettings: RatingSettings = uiSettings;
          retVal = (
            <RAFStartRatingInput
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              {...(isNotNullAndUndefined(ratingSettings) &&
              isNotNullAndUndefined(ratingSettings.Length)
                ? { length: ratingSettings.Length }
                : {})}
              titleLocation={titleLocation}
            />
          );
        } else {
          retVal = (
            <RAFNumber
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              placeholder={
                isNotNullAndUndefined(placeholder) ? placeholder : null
              }
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
            />
          );
        }

        break;
      }
      case RAFDataType.Double: {
        retVal = (
          <RAFNumber
            key={compName}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            {...(isNotNullAndUndefined(validateField)
              ? { validate: validateField }
              : {})}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            placeholder={
              isNotNullAndUndefined(placeholder) ? placeholder : null
            }
            required={isRequired}
            showLabel={showLabel}
            //initialValue={defaultValue}
            // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
            //   ? { initialValue: defaultValue }
            //   : {})}
            disabled={isDisabled}
            formGroupClassName={"mb-0"}
            onChanged={(e) => {
              onRAFFormOnchange(formValues, formRules);
            }}
            titleLocation={titleLocation}
            decimalsPoints={2}
          />
        );

        break;
      }
      case RAFDataType.Boolean: {
        retVal = (
          <RAFCheckBox
            key={compName}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            {...(isNotNullAndUndefined(validateField)
              ? { validate: validateField }
              : {})}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            required={isRequired}
            showLabel={showLabel}
            //initialValue={defaultValue}
            // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
            //   ? { initialValue: defaultValue }
            //   : {})}
            disabled={isDisabled}
            formGroupClassName={"mb-0"}
            labelPosition="right"
            labelClassName="col"
            inputFieldClassName="col-auto"
            rowClassName="row g-2 align-items-center"
            onChanged={(e) => {
              onRAFFormOnchange(formValues, formRules);
            }}
          />
        );
        break;
      }
      case RAFDataType.Date: {
        retVal = (
          <RAFDatePickerMUI
            key={compName}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            {...(isNotNullAndUndefined(validateField)
              ? { validate: validateField }
              : {})}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            placeholder={
              isNotNullAndUndefined(placeholder) ? placeholder : null
            }
            required={isRequired}
            showLabel={showLabel}
            //initialValue={defaultValue}
            // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
            //   ? { initialValue: defaultValue }
            //   : {})}
            disabled={isDisabled}
            formGroupClassName={"mb-0"}
            onChanged={(e) => {
              onRAFFormOnchange(formValues, formRules);
            }}
            titleLocation={titleLocation}
          />
        );
        break;
      }
      // case RAFDataType.DateTime: {
      //   if (compName === "StartDate" || compName === "StartTime") {
      //     retVal = (
      //       <RAFStartDateEndDatePicker
      //         key={compName}
      //         field={compName}
      //         {...(isNotNullAndUndefined(fieldItemLabel)
      //           ? { label: fieldItemLabel }
      //           : {})}
      //         {...(isNotNullAndUndefined(fieldDescription)
      //           ? { description: fieldDescription }
      //           : {})}
      //         {...(isNotNullAndUndefined(descriptionAsLabel)
      //           ? { descriptionAsLabel: descriptionAsLabel }
      //           : {})}
      //         endDateField={
      //           compName === "StartDate"
      //             ? "EndDate"
      //             : compName === "StartTime"
      //               ? "EndTime"
      //               : null
      //         }
      //         uitype={uiType}
      //         placeholder={
      //           isNotNullAndUndefined(placeholder) ? placeholder : null
      //         }
      //         required={isRequired}
      //         showLabel={showLabel}
      //         //initialValue={defaultValue}
      //         // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
      //         //   ? { initialValue: defaultValue }
      //         //   : {})}
      //         disabled={isDisabled}
      //         formGroupClassName={"mb-0"}
      //         onChanged={(e) => {
      //           onRAFFormOnchange(formValues, formRules);
      //         }}
      //         titleLocation={titleLocation}
      //       />
      //     );
      //   } else {
      //     retVal = (
      //       <RAFDateTimePicker
      //         key={compName}
      //         field={
      //           isNotNullAndUndefined(formDataFieldName)
      //             ? formDataFieldName + "." + compName
      //             : compName
      //         }
      //         {...(isNotNullAndUndefined(fieldItemLabel)
      //           ? { label: fieldItemLabel }
      //           : {})}
      //         {...(isNotNullAndUndefined(fieldDescription)
      //           ? { description: fieldDescription }
      //           : {})}
      //         {...(isNotNullAndUndefined(descriptionAsLabel)
      //           ? { descriptionAsLabel: descriptionAsLabel }
      //           : {})}
      //         placeholder={
      //           isNotNullAndUndefined(placeholder) ? placeholder : null
      //         }
      //         required={isRequired}
      //         showLabel={showLabel}
      //         //initialValue={defaultValue}
      //         // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
      //         //   ? { initialValue: defaultValue }
      //         //   : {})}
      //         disabled={isDisabled}
      //         formGroupClassName={"mb-0"}
      //         onChanged={(e) => {
      //           onRAFFormOnchange(formValues, formRules);
      //         }}
      //         titleLocation={titleLocation}
      //         uiType={
      //           isNotNullAndUndefined(uiType) ? uiType : RAFUIType.DateTime
      //         }
      //       />
      //     );
      //   }
      //   break;
      // }
      case RAFDataType.DateTime: {
        retVal = (
          <RAFDateTimePicker
            key={compName}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            placeholder={
              isNotNullAndUndefined(placeholder) ? placeholder : null
            }
            required={isRequired}
            showLabel={showLabel}
            //initialValue={defaultValue}
            // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
            //   ? { initialValue: defaultValue }
            //   : {})}
            disabled={isDisabled}
            formGroupClassName={"mb-0"}
            onChanged={(e) => {
              onRAFFormOnchange(formValues, formRules);
            }}
            titleLocation={titleLocation}
            //uiType={isNotNullAndUndefined(uiType) ? uiType : RAFUIType.DateTime}
          />
        );
        break;
      }
      case RAFDataType.Dropdown: {
        if (
          isNotNullAndUndefined(uiType) &&
          uiType === RAFUIType.SimpleDropdown
        ) {
          if (multiselect === true) {
            retVal = (
              <RAFMultiSelectDropdown
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                formGroupClassName={"mb-0"}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
              >
                {valueJson &&
                  valueJson.map((opt, index) => {
                    return (
                      <RAFChoiceOption
                        key={opt.Id}
                        label={opt.DisplayName}
                        value={opt.Name}
                      />
                    );
                  })}
              </RAFMultiSelectDropdown>
            );
          } else {
            retVal = (
              <RAFDropdownCC
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                textField={"DisplayName"}
                valueField={"DisplayName"}
                formGroupClassName={"mb-0"}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
              >
                {valueJson &&
                  valueJson.map((opt, index) => {
                    return (
                      <RAFChoiceOption
                        key={opt.Id}
                        label={opt.DisplayName}
                        value={opt.Name}
                      />
                    );
                  })}
              </RAFDropdownCC>
            );
          }
        } else if (
          isNotNullAndUndefined(uiType) &&
          uiType === RAFUIType.CheckboxList
        ) {
          if (multiselect === true) {
            retVal = (
              <RAFMultiSelectDropdown
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                formGroupClassName={"mb-0"}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
                uitype="checkbox"
              >
                {valueJson &&
                  valueJson.map((opt, index) => {
                    return (
                      <RAFChoiceOption
                        key={opt.Id}
                        label={opt.DisplayName}
                        value={opt.Name}
                      />
                    );
                  })}
              </RAFMultiSelectDropdown>
            );
          }
        } else if (IsNullOrWhiteSpace(uiType)) {
          if (multiselect !== true) {
            retVal = (
              <RAFDropdownCC
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                textField={"DisplayName"}
                valueField={"DisplayName"}
                formGroupClassName={"mb-0"}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
              >
                {valueJson &&
                  valueJson.map((opt, index) => {
                    return (
                      <RAFChoiceOption
                        key={opt.Id}
                        label={opt.DisplayName}
                        value={opt.Name}
                      />
                    );
                  })}
              </RAFDropdownCC>
            );
          }
        } else if (uiType === RAFUIType.ColoredDropdown) {
          retVal = (
            <RAFDropdownCC
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              displayDropdownType="customDropdownBtnForMinCount"
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              //isAppendValueJson
              //ValueJson={component.ValueJson}
              uitype="colorpicker"
              isColorOption
              //allowAdd={true}
              //mode="squareView"
              queryAttribute={{
                AttributeUID: attribUID,
                DataType: dataType,
                DisplayName: displayName,
                IsMultiSelect: multiselect,
                RelatedEntities: relatedEntities,
                UIType: uiType,
                ValueJson: valueJson,
              }}
              moduleName={entityName}
              formGroupClassName={"mb-0"}
              //createOptionMode={'Footer'}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
            >
              {/* {valueJson &&
              valueJson.map((opt, index) => {
                return (
                  <RAFChoiceOption
                    key={opt.Id}
                    label={opt.DisplayName}
                    value={opt.Name}
                  />
                );
              })} */}
            </RAFDropdownCC>
          );
        } else if (uiType === RAFUIType.ToggleButton) {
          retVal = (
            <RAFRadioButtonList
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              uitype={"customButton"}
              formGroupClassName={"mb-0"}
              inputFieldClassName="col-12"
              onChanged={(e) => onRAFFormOnchange(formValues, formRules)}
              titleLocation={titleLocation}
              // editorStyle="Collapsible"
            >
              {valueJson &&
                valueJson.map((opt, index) => {
                  return (
                    <RAFChoiceOption
                      key={opt.Id}
                      label={opt.DisplayName}
                      value={opt.Name}
                    />
                  );
                })}
            </RAFRadioButtonList>
          );
        } else if (uiType === RAFUIType.RadioButton) {
          retVal = (
            <RAFRadioButtonList
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
            >
              {valueJson &&
                valueJson.map((opt, index) => {
                  return (
                    <RAFChoiceOption
                      key={opt.Id}
                      label={opt.DisplayName}
                      value={opt.Name}
                    />
                  );
                })}
            </RAFRadioButtonList>
          );
        } else if (uiType === RAFUIType.ComboBox) {
          retVal = (
            <RAFComboBoxCC
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              moduleName={entityName}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              queryAttribute={{
                AttributeUID: attribUID,
                DataType: dataType,
                DisplayName: displayName,
                IsMultiSelect: multiselect,
                RelatedEntities: relatedEntities,
                UIType: uiType,
                ValueJson: valueJson,
              }}
            ></RAFComboBoxCC>
          );
        } else if (uiType === RAFUIType.SmileyToggle) {
          // retVal = (
          //   <RAFRadioButtonList
          //     key={compName}
          //     field={
          //       isNotNullAndUndefined(formDataFieldName)
          //         ? formDataFieldName + "." + compName
          //         : compName
          //     }
          //     {...(isNotNullAndUndefined(fieldItemLabel)
          //       ? { label: fieldItemLabel }
          //       : {})}
          //     {...(isNotNullAndUndefined(validateField)
          //       ? { validate: validateField }
          //       : {})}
          //     {...(isNotNullAndUndefined(fieldDescription)
          //       ? { description: fieldDescription }
          //       : {})}
          //     {...(isNotNullAndUndefined(descriptionAsLabel)
          //       ? { descriptionAsLabel: descriptionAsLabel }
          //       : {})}
          //     required={isRequired}
          //     showLabel={showLabel}
          //     //initialValue={defaultValue}
          //     // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
          //     //   ? { initialValue: defaultValue }
          //     //   : {})}
          //     disabled={isDisabled}
          //     uitype={"smileyToggle"}
          //     formGroupClassName={"mb-0"}
          //     inputFieldClassName="col-auto"
          //     onChanged={(e) => onRAFFormOnchange(formValues, formRules)}
          //     titleLocation={titleLocation}
          //     // editorStyle="Collapsible"
          //   >
          //     {valueJson &&
          //       valueJson.map((opt, index) => {
          //         return (
          //           <RAFChoiceOption
          //             key={opt.Id}
          //             label={opt.DisplayName}
          //             value={opt.Name}
          //             IconCss={opt.IconCss}
          //             Icon={opt.Icon}
          //           />
          //         );
          //       })}
          //   </RAFRadioButtonList>
          // );
          retVal = (
            <RAFDropdownCC
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              uitype={uiType}
              moduleName={entityName}
              formGroupClassName={"mb-0"}
              //createOptionMode={'Footer'}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
              displayDropdownType="colorDropdown"
            >
              {valueJson &&
                valueJson.map((opt, index) => {
                  return (
                    <RAFChoiceOption
                      key={opt.Id}
                      label={opt.DisplayName}
                      value={opt.Name}
                      IconCss={opt.IconCss}
                      Icon={opt.Icon}
                    />
                  );
                })}
            </RAFDropdownCC>
          );
        } else if (
          uiType === RAFUIType.RatingWithLabel ||
          uiType === RAFUIType.Rating
        ) {
          return (
            <RAFStarRating
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
              valueJson={valueJson}
              uiType={uiType}
            />
          );
        } else if (
          uiType === RAFUIType.SentimentToggle ||
          uiType === RAFUIType.SentimentToggleWithLabel
        ) {
          return (
            <RAFSentimentToggle
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
              valueJson={valueJson}
              uiType={uiType}
            />
          );
        }
        break;
      }
      case RAFDataType.Question: {
        if (
          isNotNullAndUndefined(uiType) &&
          uiType === RAFUIType.SimpleDropdown
        ) {
          if (multiselect === true) {
            retVal = (
              <RAFMultiSelectDropdown
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                formGroupClassName={"mb-0"}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
              >
                {valueJson &&
                  valueJson.map((opt, index) => {
                    return (
                      <RAFChoiceOption
                        key={opt.Id}
                        label={opt.DisplayName}
                        value={opt.Name}
                      />
                    );
                  })}
              </RAFMultiSelectDropdown>
            );
          } else {
            retVal = (
              <RAFDropdownCC
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                textField={"DisplayName"}
                valueField={"DisplayName"}
                formGroupClassName={"mb-0"}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
                moduleName={entityName}
              >
                {valueJson &&
                  valueJson.map((opt, index) => {
                    return (
                      <RAFChoiceOption
                        key={opt.Id}
                        label={opt.DisplayName}
                        value={opt.Name}
                      />
                    );
                  })}
              </RAFDropdownCC>
            );
          }
        } else if (
          isNotNullAndUndefined(uiType) &&
          uiType === RAFUIType.CheckboxList
        ) {
          if (multiselect === true) {
            retVal = (
              <RAFMultiSelectDropdown
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                formGroupClassName={"mb-0"}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
                uitype="checkbox"
              >
                {valueJson &&
                  valueJson.map((opt, index) => {
                    return (
                      <RAFChoiceOption
                        key={opt.Id}
                        label={opt.DisplayName}
                        value={opt.Name}
                      />
                    );
                  })}
              </RAFMultiSelectDropdown>
            );
          }
        } else if (IsNullOrWhiteSpace(uiType)) {
          if (multiselect !== true) {
            retVal = (
              <RAFDropdownCC
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                textField={"DisplayName"}
                valueField={"DisplayName"}
                formGroupClassName={"mb-0"}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
              >
                {valueJson &&
                  valueJson.map((opt, index) => {
                    return (
                      <RAFChoiceOption
                        key={opt.Id}
                        label={opt.DisplayName}
                        value={opt.Name}
                      />
                    );
                  })}
              </RAFDropdownCC>
            );
          }
        } else if (uiType === RAFUIType.ColoredDropdown) {
          retVal = (
            <RAFDropdownCC
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              //isAppendValueJson
              //ValueJson={component.ValueJson}
              uitype="colorpicker"
              displayDropdownType="customDropdownBtnForMinCount"
              isColorOption
              //allowAdd={true}
              //mode="squareView"
              queryAttribute={{
                AttributeUID: attribUID,
                DataType: dataType,
                DisplayName: displayName,
                IsMultiSelect: multiselect,
                RelatedEntities: relatedEntities,
                UIType: uiType,
                ValueJson: valueJson,
              }}
              moduleName={entityName}
              formGroupClassName={"mb-0"}
              //createOptionMode={'Footer'}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
            >
              {/* {valueJson &&
              valueJson.map((opt, index) => {
                return (
                  <RAFChoiceOption
                    key={opt.Id}
                    label={opt.DisplayName}
                    value={opt.Name}
                  />
                );
              })} */}
            </RAFDropdownCC>
          );
        } else if (uiType === RAFUIType.ToggleButton) {
          retVal = (
            <RAFRadioButtonList
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              uitype={"customButton"}
              formGroupClassName={"mb-0"}
              inputFieldClassName="col-12"
              onChanged={(e) => onRAFFormOnchange(formValues, formRules)}
              titleLocation={titleLocation}
              // editorStyle="Collapsible"
            >
              {valueJson &&
                valueJson.map((opt, index) => {
                  return (
                    <RAFChoiceOption
                      key={opt.Id}
                      label={opt.DisplayName}
                      value={opt.Name}
                    />
                  );
                })}
            </RAFRadioButtonList>
          );
        } else if (uiType === RAFUIType.RadioButton) {
          retVal = (
            <RAFRadioButtonList
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
            >
              {valueJson &&
                valueJson.map((opt, index) => {
                  return (
                    <RAFChoiceOption
                      key={opt.Id}
                      label={opt.DisplayName}
                      value={opt.Name}
                    />
                  );
                })}
            </RAFRadioButtonList>
          );
        } else if (uiType === RAFUIType.ComboBox) {
          retVal = (
            <RAFComboBoxCC
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              moduleName={entityName}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              queryAttribute={{
                AttributeUID: attribUID,
                DataType: dataType,
                DisplayName: displayName,
                IsMultiSelect: multiselect,
                RelatedEntities: relatedEntities,
                UIType: uiType,
                ValueJson: valueJson,
              }}
            ></RAFComboBoxCC>
          );
        }
        break;
      }
      case RAFDataType.MultiSelect: {
        if (IsNullOrWhiteSpace(uiType) || uiType === RAFUIType.CheckboxList) {
          retVal = (
            <RAFMultiSelectDropdown
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              placeholder={
                isNotNullAndUndefined(placeholder) ? placeholder : null
              }
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
              uitype="checkbox"
            >
              {valueJson &&
                valueJson.map((opt, index) => {
                  return (
                    <RAFChoiceOption
                      key={opt.Id}
                      label={opt.DisplayName}
                      value={opt.Name}
                    />
                  );
                })}
            </RAFMultiSelectDropdown>
          );
        } else {
          retVal = (
            <RAFMultiSelectDropdown
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              placeholder={
                isNotNullAndUndefined(placeholder) ? placeholder : null
              }
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
            >
              {valueJson &&
                valueJson.map((opt, index) => {
                  return (
                    <RAFChoiceOption
                      key={opt.Id}
                      label={opt.DisplayName}
                      value={opt.Name}
                    />
                  );
                })}
            </RAFMultiSelectDropdown>
          );
        }

        break;
      }
      case RAFDataType.RadioButton: {
        if (IsNullOrWhiteSpace(uiType) || uiType === RAFUIType.RadioButton) {
          retVal = (
            <RAFRadioButtonList
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
            >
              {valueJson &&
                valueJson.map((opt, index) => {
                  return (
                    <RAFChoiceOption
                      key={opt.Id}
                      label={opt.DisplayName}
                      value={opt.Name}
                    />
                  );
                })}
            </RAFRadioButtonList>
          );
        } else if (uiType === RAFUIType.ToggleButton) {
          retVal = (
            <RAFRadioButtonList
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(validateField)
                ? { validate: validateField }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              //initialValue={defaultValue}
              // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
              //   ? { initialValue: defaultValue }
              //   : {})}
              disabled={isDisabled}
              uitype={"customButton"}
              formGroupClassName={"mb-0"}
              inputFieldClassName="col-12"
              onChanged={(e) => {
                onRAFFormOnchange(formValues, formRules);
              }}
              titleLocation={titleLocation}
              // editorStyle="Collapsible"
            >
              {valueJson &&
                valueJson.map((opt, index) => {
                  return (
                    <RAFChoiceOption
                      key={opt.Id}
                      label={opt.DisplayName}
                      value={opt.Name}
                    />
                  );
                })}
            </RAFRadioButtonList>
          );
        }
        break;
      }
      case RAFDataType.Lookup: {
        if (uiType === RAFDataType.Attachment || compName === "Attachment") {
          retVal = (
            <RAFFileAttachment
              required={isRequired}
              showLabel={showLabel}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              propertyName={compName}
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              disabled={isPreviewMode || isDisabled ? true : false}
              isMultiSelect={multiselect ?? false}
              formGroupClassName="mb-0"
              columnClass={"col-12"}
            />
          );
        } else if (uiType === RAFDataType.LinkForm) {
          retVal = (
            <CQIRegisterCreateComponent
              required={isRequired}
              showLabel={showLabel}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              disabled={isPreviewMode}
              formGroupClassName="mb-0"
              moduleName={relatedEntities}
              entityName={entityName}
            />
          );
        } else {
          //let url = getUrlByRelatedEntites(relatedEntities);
          let url = "DataList/Lookup";
          //let defaultField = getDefaultFieldNameByModule(relatedEntities);
          let createForm = getCreateComponent(relatedEntities);
          if (multiselect === true) {
            retVal = (
              <RAFMultiSelectLookupCC
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                type={ILookupRow}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                url={isNotNullAndUndefined(url) ? url : null}
                showFullList={false}
                formGroupClassName={"mb-0"}
                moduleName={relatedEntities}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
              />
            );
          } else {
            let retVal1 = (
              <RAFLookUpMUI
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                //type={ILookupRow}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                url={isNotNullAndUndefined(url) ? url : null}
                moduleName={relatedEntities}
                {...(isNotNullAndUndefined(uiType) &&
                uiType === RAFUIType.SelectAndAdd
                  ? { showCreateButton: true }
                  : {})}
                //showCreateButton
                formGroupClassName={"mb-0"}
                createform={createForm}
                //createformdefaultfield={defaultField}
                //selectedField={defaultField}
                SearchCreateOptionMode={"Footer"}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
                customFilter={getFieldCustomFilter(
                  entityName,
                  formValues,
                  RAFDataType.Lookup
                )}
              />
            );
            retVal = (
              <RAFLookupCC
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(validateField)
                  ? { validate: validateField }
                  : {})}
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                placeholder={
                  isNotNullAndUndefined(placeholder) ? placeholder : null
                }
                //type={ILookupRow}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                url={isNotNullAndUndefined(url) ? url : null}
                moduleName={relatedEntities}
                {...(isNotNullAndUndefined(uiType) &&
                uiType === RAFUIType.SelectAndAdd
                  ? { showCreateButton: true }
                  : {})}
                //showCreateButton
                formGroupClassName={"mb-0"}
                createform={createForm}
                //createformdefaultfield={defaultField}
                //selectedField={defaultField}
                SearchCreateOptionMode={"Footer"}
                onChanged={(e) => {
                  onRAFFormOnchange(formValues, formRules);
                }}
                titleLocation={titleLocation}
                customFilter={getFieldCustomFilter(
                  entityName,
                  formValues,
                  RAFDataType.Lookup
                )}
              />
            );
          }
        }
        break;
      }
      case RAFDataType.Multiline: {
        retVal = (
          <RAFTextArea
            key={compName}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            placeholder={
              isNotNullAndUndefined(placeholder) ? placeholder : null
            }
            required={isRequired}
            showLabel={showLabel}
            //initialValue={defaultValue}
            // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
            //   ? { initialValue: defaultValue }
            //   : {})}
            disabled={isDisabled}
            formGroupClassName={"mb-0"}
            onChanged={(e) => {
              onRAFFormOnchange(formValues, formRules);
            }}
            titleLocation={titleLocation}
          />
        );
        break;
      }
      case RAFDataType.Password: {
        retVal = (
          <RAFPassword
            key={compName}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            required={isRequired}
            showLabel={showLabel}
            //initialValue={defaultValue}
            // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
            //   ? { initialValue: defaultValue }
            //   : {})}
            disabled={isDisabled}
            formGroupClassName={"mb-0"}
            titleLocation={titleLocation}
          />
        );
        break;
      }
      case RAFDataType.Video: {
        let videoSettings: VideoSettings = uiSettings;
        retVal = (
          <RAFVideoInput
            key={compName}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            required={isRequired}
            showLabel={false}
            //initialValue={defaultValue}
            // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
            //   ? { initialValue: defaultValue }
            //   : {})}
            disabled={isDisabled}
            {...(isNotNullAndUndefined(videoSettings) &&
            isNotNullAndUndefined(videoSettings.Width)
              ? { mediaWidth: videoSettings.Width }
              : {})}
            {...(isNotNullAndUndefined(videoSettings) &&
            isNotNullAndUndefined(videoSettings.Height)
              ? { mediaHeight: videoSettings.Height }
              : {})}
            {...(isNotNullAndUndefined(videoSettings) &&
            isNotNullAndUndefined(videoSettings.Url)
              ? { url: videoSettings.Url }
              : {})}
            formGroupClassName={"mb-0"}
            titleLocation={titleLocation}
          />
        );
        break;
      }
      case RAFDataType.Image: {
        let imageSettings: ImageSettings = uiSettings;
        retVal = (
          <RAFImageInput
            key={compName}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            required={isRequired}
            showLabel={false}
            //initialValue={defaultValue}
            // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
            //   ? { initialValue: defaultValue }
            //   : {})}
            disabled={isDisabled}
            {...(isNotNullAndUndefined(imageSettings) &&
            isNotNullAndUndefined(imageSettings.Width)
              ? { mediaWidth: imageSettings.Width }
              : {})}
            {...(isNotNullAndUndefined(imageSettings) &&
            isNotNullAndUndefined(imageSettings.Height)
              ? { mediaHeight: imageSettings.Height }
              : {})}
            {...(isNotNullAndUndefined(imageSettings) &&
            isNotNullAndUndefined(imageSettings.Url)
              ? { url: imageSettings.Url }
              : {})}
            formGroupClassName={"mb-0"}
            titleLocation={titleLocation}
          />
        );
        break;
      }
      case RAFDataType.Form: {
        if (relatedEntities === "phone") {
          if (multiselect) {
            retVal = (
              <RAFPhone1
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                formGroupClassName={"mb-0"}
              />
            );
          } else {
            retVal = (
              <RAFTextBox
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                formGroupClassName={"mb-0"}
                titleLocation={titleLocation}
              />
            );
          }
        } else if (relatedEntities === "address") {
          if (multiselect) {
            retVal = (
              <RAFAddress1
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                formGroupClassName={"mb-0"}
              />
            );
          } else {
            retVal = (
              <RAFAddress
                field1="AddressLine1"
                field2="AddressLine2"
                field3="AddressCity"
                field4="AddressState"
                field5="AddressCountry"
                field6="AddressPostalCode"
                //   required={isRequired}
                //  showLabel={showLabel}
                // disabled={isDisabled}
              />
            );
            /*retVal = (
              <RAFAutoFillAddress
                field1="AddressLine1"
                field2="AddressLine2"
                field3="AddressCity"
                field4="AddressState"
                field5="AddressCountry"
                field6="AddressPostalCode"
                // required={isRequired}
                // showLabel={showLabel}
                // disabled={isDisabled}
              />
            );*/
          }
        } else if (relatedEntities === "email") {
          if (multiselect === true) {
            retVal = (
              <RAFEmail1
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                formGroupClassName={"mb-0"}
              />
            );
          } else {
            retVal = (
              <RAFEmailTextBox
                key={compName}
                field={
                  isNotNullAndUndefined(formDataFieldName)
                    ? formDataFieldName + "." + compName
                    : compName
                }
                {...(isNotNullAndUndefined(fieldItemLabel)
                  ? { label: fieldItemLabel }
                  : {})}
                {...(isNotNullAndUndefined(fieldDescription)
                  ? { description: fieldDescription }
                  : {})}
                {...(isNotNullAndUndefined(descriptionAsLabel)
                  ? { descriptionAsLabel: descriptionAsLabel }
                  : {})}
                required={isRequired}
                showLabel={showLabel}
                //initialValue={defaultValue}
                // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
                //   ? { initialValue: defaultValue }
                //   : {})}
                disabled={isDisabled}
                formGroupClassName={"mb-0"}
              />
            );
          }
        } else if (relatedEntities === "text_row") {
          retVal = (
            <RAFCustomTextRow
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={showLabel}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
            />
          );
        }
        break;
      }
      case RAFDataType.Heading: {
        if (mode === "Details") {
          retVal = (
            <RAFDetailField
              valueDivClassName="pb-0 pt-0"
              valueClassName="heading"
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              isSanitized
            />
          );
        }
        if (mode === "Edit") {
          retVal = (
            <RAFTextBox
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={false}
              initialValue={defaultValue}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
              titleLocation={titleLocation}
            />
          );
        }
        break;
      }
      case RAFDataType.RichText: {
        if (mode === "Details") {
          retVal = (
            <RAFDetailField
              valueDivClassName="pb-0 pt-0"
              valueClassName="richTextBox"
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              isSanitized
            />
          );
        }
        if (mode === "Edit") {
          retVal = (
            <RAFHtmlEditor
              key={compName}
              field={
                isNotNullAndUndefined(formDataFieldName)
                  ? formDataFieldName + "." + compName
                  : compName
              }
              {...(isNotNullAndUndefined(fieldItemLabel)
                ? { label: fieldItemLabel }
                : {})}
              {...(isNotNullAndUndefined(fieldDescription)
                ? { description: fieldDescription }
                : {})}
              {...(isNotNullAndUndefined(descriptionAsLabel)
                ? { descriptionAsLabel: descriptionAsLabel }
                : {})}
              required={isRequired}
              showLabel={false}
              initialValue={defaultValue}
              disabled={isDisabled}
              formGroupClassName={"mb-0"}
            />
          );
        }
        break;
      }
      case RAFDataType.Phone:
        retVal = (
          <RAFPhone1
            key={compName}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            required={isRequired}
            showLabel={showLabel}
            //initialValue={defaultValue}
            // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
            //   ? { initialValue: defaultValue }
            //   : {})}
            disabled={isDisabled}
            formGroupClassName={"mb-0"}
          />
        );
        break;
      case RAFDataType.Email:
        retVal = (
          <RAFEmail1
            key={compName}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            required={isRequired}
            showLabel={showLabel}
            //initialValue={defaultValue}
            // {...(((mode === "Create" || mode === 'Details' || isPreviewMode === true) && isNotNullAndUndefined(defaultValue))
            //   ? { initialValue: defaultValue }
            //   : {})}
            disabled={isDisabled}
            formGroupClassName={"mb-0"}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
          />
        );
        break;
      case RAFDataType.Address:
        retVal = (
          <RAFAddress
            field1="AddressLine1"
            field2="AddressLine2"
            field3="AddressCity"
            field4="AddressState"
            field5="AddressCountry"
            field6="AddressPostalCode"
            // required={isRequired}
            // showLabel={showLabel}
            // disabled={isDisabled}
          />
        );
        break;
      /*retVal = (
        <RAFAutoFillAddress
          field1="AddressLine1"
          field2="AddressLine2"
          field3="AddressCity"
          field4="AddressState"
          field5="AddressCountry"
          field6="AddressPostalCode"
          // required={isRequired}
          // showLabel={showLabel}
          // disabled={isDisabled}
        />
      );
      break;*/
      case RAFDataType.Signature:
        retVal = (
          <RAFSignaturePad
            required={isRequired}
            showLabel={showLabel}
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            formGroupClassName="mb-0"
            titleLocation={titleLocation}
          />
        );
        break;
      case RAFDataType.Paragraph:
        retVal = (
          <div
            dangerouslySetInnerHTML={{
              __html: isNotNullAndUndefined(displayName)
                ? DOMPurify.sanitize(displayName)
                : undefined,
            }}
          ></div>
        );
        break;
      case RAFDataType.Location:
        retVal = (
          <RAFLatitudeLongitude
            field={
              isNotNullAndUndefined(formDataFieldName)
                ? formDataFieldName + "." + compName
                : compName
            }
            {...(isNotNullAndUndefined(fieldItemLabel)
              ? { label: fieldItemLabel }
              : {})}
            {...(isNotNullAndUndefined(validateField)
              ? { validate: validateField }
              : {})}
            placeholder={
              isNotNullAndUndefined(placeholder) ? placeholder : null
            }
            {...(isNotNullAndUndefined(fieldDescription)
              ? { description: fieldDescription }
              : {})}
            {...(isNotNullAndUndefined(descriptionAsLabel)
              ? { descriptionAsLabel: descriptionAsLabel }
              : {})}
            required={isRequired}
            showLabel={showLabel}
            disabled={isDisabled}
            formGroupClassName={"mb-0"}
            onChanged={(e) => {
              onRAFFormOnchange(formValues, formRules);
            }}
            titleLocation={titleLocation}
            latitudeField={`${fieldName}Latitude`}
            longitudeField={`${fieldName}Longitude`}
          />
        );
        break;

      default:
        // retVal = (
        //   <div className="no-record-div">
        //     <span className="no-record-text1">Preview not available</span>
        //   </div>
        // );
        break;
    }
  }
  return retVal;
}

export function getDetailElement(
  objectData: any,
  attributeRelatedList: AttributeRelatedList,
  queryAttributes: QueryAttributeJM[],
  attributeUID: string,
  IsHidden: boolean,
  showLabel: boolean,
  navigationEnabled: boolean,
  onNavigationClicked: (relatedEntities: string, refUID: string) => void
  // onNavigationClicked: (url) => void
) {
  let retVal = <></>;
  const queryAttrib =
    queryAttributes &&
    queryAttributes.find(
      (x) =>
        x.AttributeUID === attributeUID &&
        x.Visible === true &&
        x.ShowInSelect === true &&
        x.ShowInWhere === true
    );
  if (isNullOrUndefined(queryAttrib)) {
    return retVal;
  }
  let compName: string = queryAttrib.PropertyName;
  let dataType: string = queryAttrib.DataType;
  let compValue = isNotNullAndUndefined(objectData)
    ? objectData[compName]
    : null;
  if (dataType === RAFDataType.Date && isNotNullAndUndefined(compValue)) {
    let dateValue = convertUTCDateToLocalTimezone(compValue);
    compValue = moment(dateValue).format("DD/MM/YYYY").toString();
  }

  if (dataType === RAFDataType.DateTime && isNotNullAndUndefined(compValue)) {
    let dateValue = convertUTCDateToLocalTimezone(compValue);
    compValue = moment(dateValue).format("DD/MM/YYYY hh:mm A").toString();
  }
  compValue = compValue as string;
  let displayName: string = queryAttrib.DisplayName;
  //const isRequired = queryAttrib.IsRequired;
  //const isDisabled = !queryAttrib.IsEditable;
  let relatedEntities = queryAttrib.RelatedEntities;

  let refUID: string = isNotNullAndUndefined(objectData)
    ? objectData[compName + "UID"]
    : null;

  if (isNullOrUndefined(showLabel)) {
    showLabel = true;
  }
  let showComponet = true;
  //if (validateField === true) {
  if (queryAttrib.Visible === false) {
    showComponet = false;
  }
  //}
  if (IsHidden === true) {
    showComponet = false;
  }

  let objRow: ValueJson;

  if (queryAttrib.DataType === RAFDataType.Dropdown) {
    if (queryAttrib.IsMultiSelect === true) {
      let objectValue: string[] = compValue;
      let retVal: string[] = [];
      if (isArray(objectValue)) {
        objectValue.forEach((item) => {
          let obj =
            queryAttrib.ValueJson &&
            queryAttrib.ValueJson.find(
              (x) => x.DisplayName === item || x.Name === item
            );
          retVal.push(obj && obj.DisplayName);
        });
        compValue = retVal && retVal.join(", ");
      }
    } else {
      objRow =
        queryAttrib.ValueJson &&
        queryAttrib.ValueJson.find(
          (x) => x.DisplayName === compValue || x.Name === compValue
        );
      if (isNotNullAndUndefined(compValue) && compValue) {
        let selectedValueJson = queryAttrib.ValueJson;
        if (isNotNullAndUndefined(selectedValueJson)) {
          let selectedItem = selectedValueJson.find(
            (x) => x.Name === compValue || x.DisplayName === compValue
          );
          compValue = isNotNullAndUndefined(selectedItem)
            ? selectedItem.DisplayName
            : compValue;
        }
      }
    }
  }

  if (
    queryAttrib.DataType === RAFDataType.Lookup &&
    queryAttrib.IsMultiSelect === true
  ) {
    let lookUpValue =
      isNotNullAndUndefined(compValue) &&
      isArray(compValue) &&
      compValue.length > 0
        ? compValue.map((x) => x.Value)
        : null;
    compValue = isNotNullAndUndefined(lookUpValue)
      ? lookUpValue.toString()
      : null;
  }

  if (queryAttrib.DataType === RAFDataType.Boolean) {
    if (isNotNullAndUndefined(compValue) && compValue) {
      compValue = "Yes";
    } else {
      compValue = "No";
    }
  }
  if (queryAttrib.PropertyName === "TagsListJson") {
    compValue = (
      <RAFManageTagItems
        disabled
        inputValue={compValue}
        field="TagsListJson"
        showAddBtn={false}
      >
        {" "}
      </RAFManageTagItems>
    );
  }

  if (showComponet) {
    //retVal = <RAFDetailField valueDivClassName="pb-0 pt-0" valueClassName="m-0 detailsValue" value={objectData && objectData[compName]}></RAFDetailField>;
    //retVal = <RAFDetailFieldEditableWithIcon title={displayName} iconName={compName} disabled value={objectData && objectData[compName]} onChange={(e) => this.onChange(compName, e)} type={queryAttrib.DataType} name={compName}></RAFDetailFieldEditableWithIcon>
    retVal = (
      <div className="summarySection p-0">
        <div className="summaryText">
          {showLabel && <span className="key_header">{displayName}</span>}
          <div className="key_value_div w-100 d-flex align-items-center">
            <span
              className={
                isNotNullAndUndefined(objRow) &&
                isNotNullAndUndefined(objRow.ColorCode) &&
                compValue !== "None"
                  ? "custom_key_value ecllipseFirstLine text-wrap word-break-all description-text"
                  : "key_value ecllipseFirstLine text-wrap word-break-all description-text"
              }
              style={{
                background: isNotNullAndUndefined(objRow) && objRow.ColorCode,
                color:
                  isNotNullAndUndefined(objRow) &&
                  isNotNullAndUndefined(objRow.ColorCode)
                    ? "white"
                    : isNotNullAndUndefined(navigationEnabled) &&
                      navigationEnabled === true
                    ? "rgb(0, 120, 214)"
                    : null,
                cursor: "pointer",
              }}
              // onClick={() =>
              //   isNotNullAndUndefined(navigationEnabled) &&
              //   navigationEnabled === true
              //     ? onNavigationClicked(
              //         getRedirectURLByModule(relatedEntities) + refUID
              //       )
              //     : null
              // }
              onClick={() =>
                isNotNullAndUndefined(navigationEnabled) &&
                navigationEnabled === true
                  ? onNavigationClicked(
                      relatedEntities,
                      refUID
                      //"/RAF/View/" + relatedEntities + "/" + refUID
                    )
                  : null
              }
              dangerouslySetInnerHTML={{
                __html:
                  compValue !== null
                    ? DOMPurify.sanitize(compValue)
                    : "Not set",
              }}
            ></span>
          </div>
        </div>
      </div>
    );
  }
  return retVal;
}

export function getDetailsElement(
  objectData: any,
  queryAttributes: QueryAttributeJM[],
  attributeUID: string,
  visible: boolean,
  showLabel: boolean,
  navigationEnabled: boolean,
  onNavigationClicked: (relatedEntities: string, refUID: string) => void,
  type?: "Details" | "default",
  profileCard?: boolean,
  labelName?: string
  // onNavigationClicked: (url) => void
) {
  let retVal = <></>;
  const queryAttrib =
    queryAttributes &&
    queryAttributes.find(
      (x) =>
        x.AttributeUID === attributeUID &&
        x.Visible === true &&
        x.ShowInSelect === true &&
        x.ShowInWhere === true
    );

  if (isNullOrUndefined(queryAttrib)) {
    return retVal;
  }

  const uiType = queryAttrib.UIType;
  let compName: string = queryAttrib.PropertyName;
  let dataType: string = queryAttrib.DataType;
  let compValue = isNotNullAndUndefined(objectData)
    ? objectData[compName]
    : null;
  if (dataType === RAFDataType.Date && isNotNullAndUndefined(compValue)) {
    let dateValue = convertUTCDateToLocalTimezone(compValue);
    compValue = moment(dateValue).format("DD/MM/YYYY").toString();
  }

  if (dataType === RAFDataType.DateTime && isNotNullAndUndefined(compValue)) {
    let dateValue = convertUTCDateToLocalTimezone(compValue);
    compValue = moment(dateValue).format("DD/MM/YYYY hh:mm A").toString();
  }

  compValue = compValue as string;
  let displayName: string = labelName ?? queryAttrib.DisplayName;
  let relatedEntities = queryAttrib.RelatedEntities;

  let refUID: string = isNotNullAndUndefined(objectData)
    ? objectData[compName + "UID"]
    : null;

  if (isNullOrUndefined(showLabel)) {
    showLabel = true;
  }
  let showComponent = false;
  if (visible === true) {
    showComponent = true;
  }

  let objRow: ValueJson;

  if (queryAttrib.DataType === RAFDataType.Dropdown) {
    if (queryAttrib.IsMultiSelect === true) {
      let objectValue: string[] = compValue;
      let retVal: string[] = [];
      if (isArray(objectValue)) {
        objectValue.forEach((item) => {
          let obj =
            queryAttrib.ValueJson &&
            queryAttrib.ValueJson.find(
              (x) => x.DisplayName === item || x.Name === item
            );
          retVal.push(obj && obj.DisplayName);
        });
        compValue = retVal && retVal.join(", ");
      }
    } else {
      objRow =
        queryAttrib.ValueJson &&
        queryAttrib.ValueJson.find(
          (x) => x.DisplayName === compValue || x.Name === compValue
        );
      if (isNotNullAndUndefined(compValue) && compValue) {
        let selectedValueJson = queryAttrib.ValueJson;
        if (isNotNullAndUndefined(selectedValueJson)) {
          let selectedItem = selectedValueJson.find(
            (x) => x.Name === compValue || x.DisplayName === compValue
          );
          compValue = isNotNullAndUndefined(selectedItem)
            ? selectedItem.DisplayName
            : compValue;
        }
      }
    }
  }

  if (
    queryAttrib.DataType === RAFDataType.Lookup &&
    queryAttrib.IsMultiSelect === true
  ) {
    let lookUpValue =
      isNotNullAndUndefined(compValue) &&
      isArray(compValue) &&
      compValue.length > 0
        ? compValue.map((x) => x.Value)
        : null;
    compValue = isNotNullAndUndefined(lookUpValue)
      ? lookUpValue.toString()
      : null;
  }

  if (queryAttrib.DataType === RAFDataType.Boolean) {
    if (isNotNullAndUndefined(compValue) && compValue) {
      compValue = "Yes";
    } else {
      compValue = "No";
    }
  }

  if (showComponent) {
    //retVal = <RAFDetailField valueDivClassName="pb-0 pt-0" valueClassName="m-0 detailsValue" value={objectData && objectData[compName]}></RAFDetailField>;
    //retVal = <RAFDetailFieldEditableWithIcon title={displayName} iconName={compName} disabled value={objectData && objectData[compName]} onChange={(e) => this.onChange(compName, e)} type={queryAttrib.DataType} name={compName}></RAFDetailFieldEditableWithIcon>
      if (isNotNullAndUndefined(uiType) && uiType === RAFUIType.Address) {
      let addressList: AddressRow[] = [];
      addressList.push({
        AddressType: AddressType.PostalAddress,
        AddressLine1: objectData["AddressLine1"],
        AddressLine2: objectData["AddressLine2"],
        City: objectData["AddressCity"],
        PostalCode: objectData["AddressPostalCode"],
        State: objectData["AddressState"],
        Country: objectData["AddressCountry"],
      });
      retVal = (
        <div className="summarySection p-0">
          {showLabel && (
            <div className="summaryText">
              <span
                className={type === "Details" ? "details-label" : "key_header"}
              >
                {displayName}
              </span>
            </div>
          )}
          <div className="col-sm-12 col-xl-12 p-0 d-flex align-items-center editable-detailsField-value">
            {addressList &&
              addressList.map((item, index) => {
                let addressRow = item as AddressRow;
                return (
                  <div
                    key={index}
                    className="d-flex align-self-center flex-column"
                  >
                    {/*{isNotNullAndUndefined(addressRow.AddressType) ? <div className="m-0 detailsValue" style={{ fontWeight: 500 }}>{addressRow.AddressType} Address</div> : null}*/}
                    {isNotNullAndUndefined(addressRow.AddressLine1) ? (
                      <div className="m-0 detailsValue">
                        {addressRow.AddressLine1}
                      </div>
                    ) : null}
                    <div className="d-flex">
                      {isNotNullAndUndefined(addressRow.City) ? (
                        <div className="m-0 detailsValue">
                          {addressRow.City}{" "}
                        </div>
                      ) : null}
                      {isNotNullAndUndefined(addressRow.State) ? (
                        <div className="m-0 detailsValue">
                          {" , "}
                          {addressRow.State}
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex">
                      {isNotNullAndUndefined(addressRow.Country) ? (
                        <div className="m-0 detailsValue">
                          {addressRow.Country}
                        </div>
                      ) : null}
                      {isNotNullAndUndefined(addressRow.PostalCode) ? (
                        <div className="m-0 detailsValue">
                          {" - "}
                          {addressRow.PostalCode}
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      );
    } else {
      retVal = (
        <div className="summarySection p-0">
          <div className="summaryText">
            {showLabel && (
              <span
                className={type === "Details" ? "details-label" : "key_header"}
              >
                {displayName}
              </span>
            )}
            <div
              className={`${
                queryAttrib.DataType === RAFDataType.Text &&
                (queryAttrib.UIType === RAFUIType.HtmlEditor ||
                  queryAttrib.UIType === RAFUIType.Multiline)
                  ? "key_html_value_div "
                  : ""
              }key_value_div w-100 d-flex align-items-center`}
            >
              <span
                className={
                  type === "Details"
                    ? `${
                        queryAttrib.DataType === RAFDataType.Text &&
                        (queryAttrib.UIType === RAFUIType.HtmlEditor ||
                          queryAttrib.UIType === RAFUIType.Multiline)
                          ? "description-text "
                          : ""
                      }details-value`
                    : isNotNullAndUndefined(objRow) &&
                      isNotNullAndUndefined(objRow.ColorCode) &&
                      compValue !== "None"
                    ? "custom_key_value ecllipseFirstLine text-wrap word-break-all description-text"
                    : "key_value ecllipseFirstLine text-wrap word-break-all description-text"
                }
                style={{
                  background: isNotNullAndUndefined(objRow) && objRow.ColorCode,
                  color:
                    isNotNullAndUndefined(objRow) &&
                    isNotNullAndUndefined(objRow.ColorCode)
                      ? "white"
                      : isNotNullAndUndefined(navigationEnabled) &&
                        navigationEnabled === true
                      ? "rgb(0, 120, 214)"
                      : null,
                  cursor: "pointer",
                }}
                // onClick={() =>
                //   isNotNullAndUndefined(navigationEnabled) &&
                //   navigationEnabled === true
                //     ? onNavigationClicked(
                //         getRedirectURLByModule(relatedEntities) + refUID
                //       )
                //     : null
                // }
                onClick={() =>
                  isNotNullAndUndefined(navigationEnabled) &&
                  navigationEnabled === true
                    ? onNavigationClicked(
                        relatedEntities,
                        refUID
                        //"/RAF/View/" + relatedEntities + "/" + refUID
                      )
                    : null
                }
                dangerouslySetInnerHTML={{
                  __html:
                    compValue !== null
                      ? DOMPurify.sanitize(compValue)
                      : "Not set",
                }}
              ></span>
            </div>
          </div>
        </div>
      );
    }
  }

  if (profileCard) {
    retVal = (
      <div className="row gy-2 gx-0">
        <div className="col-12 profile_card_details_label">
          <span>{displayName}</span>
        </div>
        <div className="col-12 profile_card_details_value">
          <span
            className={
              type === "Details"
                ? `${
                    queryAttrib.DataType === RAFDataType.Text &&
                    (queryAttrib.UIType === RAFUIType.HtmlEditor ||
                      queryAttrib.UIType === RAFUIType.Multiline)
                      ? "description-text "
                      : ""
                  }`
                : ""
            }
            onClick={() =>
              isNotNullAndUndefined(navigationEnabled) &&
              navigationEnabled === true
                ? onNavigationClicked(
                    relatedEntities,
                    refUID
                    //"/RAF/View/" + relatedEntities + "/" + refUID
                  )
                : null
            }
            dangerouslySetInnerHTML={{
              __html:
                compValue !== null ? DOMPurify.sanitize(compValue) : "Not set",
            }}
          ></span>
        </div>
      </div>
    );
  }
  return retVal;
}

export function getDetailsCustomElement(
  objectData: any,
  queryAttributes: QueryAttributeJM[],
  attributeName: string,
  visible: boolean,
  showLabel: boolean,
  navigationEnabled: boolean,
  onNavigationClicked: (relatedEntities: string, refUID: string) => void,
  title?: string,
  type?: "Details" | "default",
  profileCard?: boolean

  // onNavigationClicked: (url) => void
) {
  let retVal = <></>;
  const queryAttrib =
    queryAttributes &&
    queryAttributes.find(
      (x) =>
        x.AttributeName === attributeName &&
        x.Visible === true &&
        x.ShowInSelect === true &&
        x.ShowInWhere === true
    );

  if (isNullOrUndefined(queryAttrib)) {
    return retVal;
  }
  let compName: string = queryAttrib.PropertyName;
  let dataType: string = queryAttrib.DataType;
  let compValue = isNotNullAndUndefined(objectData)
    ? objectData[compName]
    : null;
  if (dataType === RAFDataType.Date && isNotNullAndUndefined(compValue)) {
    let dateValue = convertUTCDateToLocalTimezone(compValue);
    compValue = moment(dateValue).format("DD/MM/YYYY").toString();
  }

  if (dataType === RAFDataType.DateTime && isNotNullAndUndefined(compValue)) {
    let dateValue = convertUTCDateToLocalTimezone(compValue);
    compValue = moment(dateValue).format("DD/MM/YYYY hh:mm A").toString();
  }
  compValue = compValue as string;
  //let displayName: string = queryAttrib.DisplayName;
  let displayName: string = title;
  let relatedEntities = queryAttrib.RelatedEntities;

  let refUID: string = isNotNullAndUndefined(objectData)
    ? objectData[compName + "UID"]
    : null;

  if (isNullOrUndefined(showLabel)) {
    showLabel = true;
  }
  let showComponent = false;
  if (visible === true) {
    showComponent = true;
  }

  let objRow: ValueJson;

  if (queryAttrib.DataType === RAFDataType.Dropdown) {
    if (queryAttrib.IsMultiSelect === true) {
      let objectValue: string[] = compValue;
      let retVal: string[] = [];
      if (isArray(objectValue)) {
        objectValue.forEach((item) => {
          let obj =
            queryAttrib.ValueJson &&
            queryAttrib.ValueJson.find(
              (x) => x.DisplayName === item || x.Name === item
            );
          retVal.push(obj && obj.DisplayName);
        });
        compValue = retVal && retVal.join(", ");
      }
    } else {
      objRow =
        queryAttrib.ValueJson &&
        queryAttrib.ValueJson.find(
          (x) => x.DisplayName === compValue || x.Name === compValue
        );
      if (isNotNullAndUndefined(compValue) && compValue) {
        let selectedValueJson = queryAttrib.ValueJson;
        if (isNotNullAndUndefined(selectedValueJson)) {
          let selectedItem = selectedValueJson.find(
            (x) => x.Name === compValue || x.DisplayName === compValue
          );
          compValue = isNotNullAndUndefined(selectedItem)
            ? selectedItem.DisplayName
            : compValue;
        }
      }
    }
  }

  if (
    queryAttrib.DataType === RAFDataType.Lookup &&
    queryAttrib.IsMultiSelect === true
  ) {
    let lookUpValue =
      isNotNullAndUndefined(compValue) &&
      isArray(compValue) &&
      compValue.length > 0
        ? compValue.map((x) => x.Value)
        : null;
    compValue = isNotNullAndUndefined(lookUpValue)
      ? lookUpValue.toString()
      : null;
  }

  if (queryAttrib.DataType === RAFDataType.Boolean) {
    if (isNotNullAndUndefined(compValue) && compValue) {
      compValue = "Yes";
    } else {
      compValue = "No";
    }
  }

  if (attributeName === "ndia_number" && isNotNullAndUndefined(compValue)) {
    compValue = compValue.replace(/./g, "*");
  }
  if (showComponent) {
    //retVal = <RAFDetailField valueDivClassName="pb-0 pt-0" valueClassName="m-0 detailsValue" value={objectData && objectData[compName]}></RAFDetailField>;
    //retVal = <RAFDetailFieldEditableWithIcon title={displayName} iconName={compName} disabled value={objectData && objectData[compName]} onChange={(e) => this.onChange(compName, e)} type={queryAttrib.DataType} name={compName}></RAFDetailFieldEditableWithIcon>
    retVal = (
      <div className="summarySection p-0">
        <div className="summaryText">
          {showLabel && (
            <span
              className={type === "Details" ? "details-label" : "key_header"}
            >
              {displayName}
            </span>
          )}
          <div
            className={`${
              queryAttrib.DataType === RAFDataType.Text &&
              (queryAttrib.UIType === RAFUIType.HtmlEditor ||
                queryAttrib.UIType === RAFUIType.Multiline)
                ? "key_html_value_div "
                : ""
            }key_value_div w-100 d-flex align-items-center`}
          >
            <span
              className={
                type === "Details"
                  ? `${
                      queryAttrib.DataType === RAFDataType.Text &&
                      (queryAttrib.UIType === RAFUIType.HtmlEditor ||
                        queryAttrib.UIType === RAFUIType.Multiline)
                        ? "description-text "
                        : ""
                    }details-value`
                  : isNotNullAndUndefined(objRow) &&
                    isNotNullAndUndefined(objRow.ColorCode) &&
                    compValue !== "None"
                  ? "custom_key_value ecllipseFirstLine text-wrap word-break-all description-text"
                  : "key_value ecllipseFirstLine text-wrap word-break-all description-text"
              }
              style={{
                background: isNotNullAndUndefined(objRow) && objRow.ColorCode,
                color:
                  isNotNullAndUndefined(objRow) &&
                  isNotNullAndUndefined(objRow.ColorCode)
                    ? "white"
                    : isNotNullAndUndefined(navigationEnabled) &&
                      navigationEnabled === true
                    ? "rgb(0, 120, 214)"
                    : null,
                cursor: "pointer",
              }}
              // onClick={() =>
              //   isNotNullAndUndefined(navigationEnabled) &&
              //   navigationEnabled === true
              //     ? onNavigationClicked(
              //         getRedirectURLByModule(relatedEntities) + refUID
              //       )
              //     : null
              // }
              onClick={() =>
                isNotNullAndUndefined(navigationEnabled) &&
                navigationEnabled === true
                  ? onNavigationClicked(
                      relatedEntities,
                      refUID
                      //"/RAF/View/" + relatedEntities + "/" + refUID
                    )
                  : null
              }
              dangerouslySetInnerHTML={{
                __html:
                  compValue !== null
                    ? DOMPurify.sanitize(compValue)
                    : "Not set",
              }}
            ></span>
          </div>
        </div>
      </div>
    );
  }
  if (profileCard) {
    retVal = (
      <div className="row gy-2 gx-0">
        <div className="col-12 profile_card_details_label">
          <span>{displayName}</span>
        </div>
        <div className="col-12 profile_card_details_value">
          <span
            className={
              type === "Details"
                ? `${
                    queryAttrib.DataType === RAFDataType.Text &&
                    (queryAttrib.UIType === RAFUIType.HtmlEditor ||
                      queryAttrib.UIType === RAFUIType.Multiline)
                      ? "description-text "
                      : ""
                  }`
                : ""
            }
            onClick={() =>
              isNotNullAndUndefined(navigationEnabled) &&
              navigationEnabled === true
                ? onNavigationClicked(
                    relatedEntities,
                    refUID
                    //"/RAF/View/" + relatedEntities + "/" + refUID
                  )
                : null
            }
            dangerouslySetInnerHTML={{
              __html:
                compValue !== null ? DOMPurify.sanitize(compValue) : "Not set",
            }}
          ></span>
        </div>
      </div>
    );
  }
  return retVal;
}

export function getDetailElementEditable(
  objectData: any,
  attributeRelatedList: AttributeRelatedList,
  queryAttributes: QueryAttributeJM[],
  attributeUID: string,
  IsHidden: boolean,
  showLabel: boolean,
  columnCount: number
) {
  let retVal = <></>;
  const queryAttrib =
    queryAttributes &&
    queryAttributes.find(
      (x) =>
        x.AttributeUID === attributeUID &&
        x.Visible === true &&
        x.ShowInSelect === true &&
        x.ShowInWhere === true
    );
  if (isNullOrUndefined(queryAttrib)) {
    return retVal;
  }
  let compName: string = queryAttrib.PropertyName;
  //console.log('attributeRelatedList', attributeRelatedList, queryAttrib, queryAttributes);
  //const isRequired = queryAttrib.IsRequired;
  //const isDisabled = !queryAttrib.IsEditable;
  if (isNullOrUndefined(showLabel)) {
    showLabel = true;
  }
  let showComponet = true;
  //if (validateField === true) {
  if (queryAttrib.Visible === false) {
    showComponet = false;
  }
  //}
  if (IsHidden === true) {
    showComponet = false;
  }

  if (showComponet) {
    retVal = (
      <div>
        <RAFDetailFieldEditable
          title={queryAttrib.DisplayName}
          disabled
          value={objectData && objectData[compName]}
          //onChange={(e) => this.onChange(compName, e)}
          valueClassName="labelPrimartyText"
          type={queryAttrib.DataType}
          name={compName}
          columnCount={columnCount}
        ></RAFDetailFieldEditable>
      </div>
    );
  }
  return retVal;
}
