import { PortalTreeViewItemRow } from "./WorkspaceItemHelper";

export const SkytrustMainMenuData: PortalTreeViewItemRow[] = [
    {
        "UID": "5af202ca-3c67-4c00-a550-915ebbb485d1",
        "Name": "home",
        "Text": "Home",
        "Icon": "home",
        "URL": "/",
        "IsExternalIcon": false,
        "NodeChild": [],
        "HasChildren": false,
        "Entity": null,
        "ParentUID": null
    },
    {
        "UID": "0e01d785-13a4-47ba-bce7-bbeb50828677",
        "Name": "inbox",
        "Text": "Inbox",
        "Icon": "file-alt",
        "URL": "/Inbox",
        "IsExternalIcon": false,
        "NodeChild": [],
        "HasChildren": false,
        "Entity": null,
        "ParentUID": null
    },
    {
        "UID": "8623aea9-b272-4b5f-accf-5712a907cd77",
        "Name": "task_dashboard",
        "Text": "Tasks",
        "Icon": "bars-progress",
        "URL": "/taskdashboard",
        "Entity": "task",
        "IsExternalIcon": false,
        "ItemType": "Module",
        "NodeChild": [
            {
                "UID": "task_My Tasks",
                "Name": "My Tasks",
                "Text": "Upcoming Tasks",
                "Icon": "bars-progress",
                "URL": "/taskdashboard",
                "Entity": "task",
                "ParentUID": "8623aea9-b272-4b5f-accf-5712a907cd77",
                "IsExternalIcon": false,
                "ItemType": "View",
                "NodeChild": [],
                "HasChildren": false,
                "PermissionName": "task::read"
            },
            {
                "UID": "task_Tasks Assigned by Me",
                "Name": "Tasks Assigned by Me",
                "Text": "Assigned by Me",
                "Icon": "bars-progress",
                "URL": "/taskdashboard",
                "Entity": "task",
                "ParentUID": "8623aea9-b272-4b5f-accf-5712a907cd77",
                "IsExternalIcon": false,
                "ItemType": "View",
                "NodeChild": [],
                "HasChildren": false,
                "PermissionName": "task::read"
            },
            {
                "UID": "task_Completed Tasks",
                "Name": "Completed Tasks",
                "Text": "Completed Tasks",
                "Icon": "bars-progress",
                "URL": "/taskdashboard",
                "Entity": "task",
                "ParentUID": "8623aea9-b272-4b5f-accf-5712a907cd77",
                "IsExternalIcon": false,
                "ItemType": "View",
                "NodeChild": [],
                "HasChildren": false,
                "PermissionName": "task::read"
            }
        ],
        "HasChildren": true,
        "ParentUID": null,
        "PermissionName": "task::read"
    },
    {
        UID: "3e8c6d9b-a3ac-4b79-87f9-442cfcc11e46",
        Name: "task_action_center",
        Text: "Action Center",
        Icon: "FaRegWindowRestore",
        URL: "/action_center",
        Entity: "task_action_center",
        ParentUID: null,
        IsExternalIcon: true,
        ItemType: "Module",
        NodeChild: [],
        HasChildren: false,
        PermissionName: "task::read",
    },
    {
        "UID": "f2491d2c-39ca-485b-8f0e-e7d11e8147a3",
        "Name": "contact",
        "Text": "Contacts",
        "Icon": "FaAddressCard",
        "URL": "/contact/list/",
        "Entity": "contact",
        "ParentUID": null,
        "IsExternalIcon": true,
        "ItemType": "Module",
        "NodeChild": [],
        "HasChildren": false,
        "PermissionName": "contact::read"
    },
    {
        "UID": "7542d46d-3830-4810-9f6b-3a085ba064f7",
        "Name": "account",
        "Text": "Companies",
        "Icon": "FaBuilding",
        "URL": "/company/list/",
        "Entity": "account",
        "ParentUID": null,
        "IsExternalIcon": true,
        "ItemType": "Module",
        "NodeChild": [],
        "HasChildren": false,
        "PermissionName": "account::read"
    },
    {
        "UID": "fa081d34-f884-48b5-b065-fce5ad6a9665",
        "Name": "deal",
        "Text": "Deals",
        "Icon": "FaDollarSign",
        "URL": "/deal/list/",
        "Entity": "deal",
        "ParentUID": null,
        "IsExternalIcon": true,
        "ItemType": "Module",
        "NodeChild": [],
        "HasChildren": false,
        "PermissionName": "deal::read"
    },
    {
        "UID": "33759cb0-c818-44c5-9aa3-2396a18c51f4",
        "Name": "ticket",
        "Text": "Tickets",
        "Icon": "FaTicketAlt",
        "URL": "/ticket/list/",
        "Entity": "ticket",
        "ParentUID": null,
        "IsExternalIcon": true,
        "ItemType": "Module",
        "NodeChild": [],
        "HasChildren": false,
        "PermissionName": "ticket::read"
    },
    {
        "UID": "a785884e-f07e-459d-826b-7d834204e502",
        "Name": "content_library",
        "Text": "Company Documents",
        "Icon": "FaFileAlt",
        "URL": "/document/list/",
        "Entity": "content_library",
        "ParentUID": null,
        "IsExternalIcon": true,
        "ItemType": "Module",
        "NodeChild": [],
        "HasChildren": false,
        "PermissionName": "content_library::read"
    },
    {
        "UID": "b523db2b-1302-4fed-a02f-916b5405d642",
        "Name": "email",
        "Text": "Emails",
        "Icon": "FaEnvelope",
        "URL": "/email/list/",
        "Entity": "email",
        "ParentUID": null,
        "IsExternalIcon": true,
        "ItemType": "Module",
        "NodeChild": [],
        "HasChildren": false,
        "PermissionName": "email::read"
    },
    {
        "UID": "41bdf21f-2046-4b28-82b3-b880bba639ba",
        "Name": "template",
        "Text": "Message Templates",
        "Icon": "FaFileSignature",
        "URL": "/template/list/",
        "Entity": "template",
        "ParentUID": null,
        "IsExternalIcon": true,
        "ItemType": "Module",
        "NodeChild": [],
        "HasChildren": false,
        "PermissionName": "template::read"
    }
];