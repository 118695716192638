import React, { Fragment, PropsWithChildren, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { getURLNameByModuleName } from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  Guid,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFAttributesContext } from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFPageType } from "../../../constants/Common/RMConstants";
import { EntitySimpleJM } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { getPageLayoutByPageTypeAndEntity } from "../../../RAFMaster/RMModules/RAFPage/RAFPageHelper";
import { NavigateParamsProps } from "../../../router";
import { RAFEntityContext } from "../Providers/RAFEntityProvider";
import { RAFObjectContext } from "../Providers/RAFObjectContextProvider";
import { getDetailsElement } from "../RAFFieldHelper";
import { RAFDataModelRow } from "../../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import { RAFAttributeName } from "../../../constants/Common/Constants";
interface IProps {
  contextModuleName?: string;
  contextObjectUID?: string;
  defaultComponent?: React.ReactFragment | ReactNode;
  tagComponent?: React.ReactFragment | ReactNode;
  isDynamic?: boolean;
  removePadding?: boolean;
  type?: "Details" | "default";
  profileCard?: boolean;
}

export default function SummarySectionFields({
  contextModuleName,
  contextObjectUID,
  defaultComponent,
  tagComponent,
  isDynamic,
  children,
  ...props
}: PropsWithChildren<IProps & NavigateParamsProps>) {
  const [summaryPageLayout, setDetailPageLayout] = React.useState(null);
  //const [defaultPageLayout, setDefaultPageLayout] = React.useState(null);

  const entityContext = React.useContext(RAFEntityContext);
  const entityRow: EntitySimpleJM = entityContext.entity;

  const attributeContext = React.useContext(RAFAttributesContext);
  const attributeRelatedList = attributeContext.attributeRelatedList;
  const queryAttributes = attributeContext.queryAttributes;

  const navigate = useNavigate();

  const removePadding =
    isNotNullAndUndefined(props.removePadding) && props.removePadding
      ? props.removePadding
      : false;

  React.useEffect(() => {
    if (isNullOrUndefined(entityRow)) {
      return;
    }
    let progressDiv = showProgress("body", true);
    getPageLayoutByPageTypeAndEntity(entityRow.UID, RAFPageType.Summary).then(
      (summaryPageLayout) => {
        // getPageLayoutByPageTypeAndEntity(
        //   entityRow.UID,
        //   RAFPageType.Default
        // ).then((defaultPageLayout) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(summaryPageLayout) &&
          isNotNullAndUndefined(summaryPageLayout.UID)
        ) {
          setDetailPageLayout(summaryPageLayout);
        }
        // else {
        //   if (
        //     isNotNullAndUndefined(defaultPageLayout) &&
        //     isNotNullAndUndefined(defaultPageLayout.UID)
        //   ) {
        //     setDefaultPageLayout(defaultPageLayout);
        //   }
        // }
        //});
      }
    );
  }, [entityRow, contextModuleName, contextObjectUID]);

  let fieldsToShow: boolean;
  let summaryFormComponents: RAFDataModelRow[] = [];
  //let defalultFormComponents: RAFDataModelRow[] = [];
  if (
    isNotNullAndUndefined(summaryPageLayout) &&
    isNotNullAndUndefined(summaryPageLayout.Components) &&
    isNotNullAndUndefined(summaryPageLayout.Components.Components)
  ) {
    summaryFormComponents = summaryPageLayout.Components.Components;
    if (
      isNotNullAndUndefined(summaryFormComponents) &&
      summaryFormComponents.length > 0
    ) {
      summaryFormComponents.forEach((component) => {
        let columns = component.Components;
        if (isNotNullAndUndefined(columns) && columns.length > 0) {
          columns.forEach((column) => {
            if (
              isNotNullAndUndefined(column) &&
              isNotNullAndUndefined(column.Components) &&
              column.Components.length > 0
            ) {
              fieldsToShow = true;
            } else {
              fieldsToShow = false;
            }
          });
        }
      });
    } else {
      fieldsToShow = false;
    }
  }
  // else if (
  //   isNotNullAndUndefined(defaultPageLayout) &&
  //   isNotNullAndUndefined(defaultPageLayout.Components) &&
  //   isNotNullAndUndefined(defaultPageLayout.Components.Components)
  // ) {
  //   defalultFormComponents = defaultPageLayout.Components.Components;

  //   if (
  //     isNotNullAndUndefined(defalultFormComponents) &&
  //     defalultFormComponents.length > 0
  //   ) {
  //     defalultFormComponents.forEach((component) => {
  //       let columns = component.Components;
  //       if (isNotNullAndUndefined(columns) && columns.length > 0) {
  //         columns.forEach((column) => {
  //           if (
  //             isNotNullAndUndefined(column) &&
  //             isNotNullAndUndefined(column.Components) &&
  //             column.Components.length > 0
  //           ) {
  //             fieldsToShow = true;
  //           } else {
  //             fieldsToShow = false;
  //           }
  //         });
  //       }
  //     });
  //   } else {
  //     fieldsToShow = false;
  //   }
  // }
  else {
    fieldsToShow = false;
  }

  function onNavigationClicked(relatedEntity: string, relatedUID: string) {
    if (
      isNotNullAndUndefined(relatedEntity) &&
      isNotNullAndUndefined(relatedUID)
    ) {
      let url =
        "/" + getURLNameByModuleName(relatedEntity) + "/view/" + relatedUID;
      if (isNotNullAndUndefined(isDynamic) && isDynamic === true) {
        url = "/RAF/View/" + relatedEntity + "/" + relatedUID;
      }
      navigate(url);
    }
  }

  let formFields = isNotNullAndUndefined(queryAttributes)
    ? queryAttributes.filter(
        (x) =>
          x.AttributeName !== RAFAttributeName.RecordInfo &&
          x.AttributeName !== RAFAttributeName.UID &&
          x.AttributeName !== RAFAttributeName.Entity &&
          x.AttributeName !== RAFAttributeName.FormLibrary &&
          x.AttributeName !== RAFAttributeName.TagsListJson &&
          x.AttributeName !== RAFAttributeName.TagsListJsonText &&
          x.AttributeName !== RAFAttributeName.Portal &&
          x.AttributeName !== RAFAttributeName.BusinessProcess &&
          x.AttributeName !== RAFAttributeName.ProcessStatus &&
          x.DataType !== RAFDataType.Formula &&
          x.DataType !== RAFDataType.AutoNumber &&
          x.ShowInSelect === true &&
          x.ShowInWhere === true
      )
    : null;

  let defaultFormComponent: RAFDataModelRow = new RAFDataModelRow();
  defaultFormComponent.UID = Guid.newGuid();
  defaultFormComponent.DisplayName = "Panel";
  defaultFormComponent.Name = "panel_" + defaultFormComponent.UID;
  defaultFormComponent.Visible = true;
  defaultFormComponent.DataType = RAFDataType.Panel;
  defaultFormComponent.IsCollapsed = false;
  defaultFormComponent.ColumnCount = 2;
  defaultFormComponent.ShowHeader = true;
  defaultFormComponent.Components = [
    {
      UID: Guid.newGuid(),
      DisplayName: "Column1",
      Name: "column_" + Guid.newGuid(),
      Visible: true,
      DataType: RAFDataType.Column,
      FieldToSave: false,
      Components: [],
    },
    {
      UID: Guid.newGuid(),
      DisplayName: "Column2",
      Name: "column_" + Guid.newGuid(),
      Visible: true,
      DataType: RAFDataType.Column,
      FieldToSave: false,
      Components: [],
    },
  ];

  if (isNotNullAndUndefined(formFields) && formFields.length > 0) {
    formFields.forEach((item, index) => {
      let formComponent: RAFDataModelRow = new RAFDataModelRow();
      formComponent.UID = item.AttributeUID;
      formComponent.Name = item.AttributeName;
      formComponent.DataType = item.DataType;
      formComponent.DisplayName = item.DisplayName;
      formComponent.DisplayOrder = item.DisplayOrder;
      formComponent.AttributeSettings = {
        EnableMassUpdate: item.EnableMassUpdate,
        DefaultValue: item.DefaultValue,
        IsRequired: item.IsRequired,
        UIType: item.UIType,
      };
      formComponent.ShowInList = item.ShowInSelect;
      formComponent.ShowInFilter = item.ShowInWhere;
      formComponent.Visible = item.Visible;
      formComponent.IsDefault = item.IsDefault;
      formComponent.IsMasterDetail = item.IsMasterDetail;
      formComponent.ValueJson = item.ValueJson;
      formComponent.EntityUID = item.EntityUID;
      if (index % 2 === 0) {
        defaultFormComponent.Components[0].Components.push({
          ...formComponent,
        });
      } else {
        defaultFormComponent.Components[1].Components.push({
          ...formComponent,
        });
      }
    });
  }

  if (fieldsToShow) {
    if (isNotNullAndUndefined(summaryPageLayout)) {
      return (
        <div className={removePadding ? "" : "p-3 pb-2 pt-1"}>
          {isNotNullAndUndefined(summaryFormComponents) &&
          summaryFormComponents.length > 0 ? (
            summaryFormComponents.map((section, index) => {
              return (
                <div
                  key={section.UID + "_" + index}
                  className="row gx-2 align-items-baseline summary-section-outer customSummary label-left-align"
                >
                  {isNotNullAndUndefined(section.Components) &&
                  section.Components.length > 0 ? (
                    section.Components.map((column, index) => {
                      return (
                        <div
                          className={
                            section.Components.length === 1
                              ? "col"
                              : section.Components.length === 2
                              ? "col-md-6"
                              : section.Components.length === 3
                              ? "col-md-4"
                              : "col-md-3"
                          }
                          key={column.UID + "_" + index}
                        >
                          <div className="w-100 h-100 row gx-2">
                            {isNotNullAndUndefined(column.Components) &&
                              column.Components.map((field, index) => {
                                let formItemWidth = isNotNullAndUndefined(
                                  field.Width
                                )
                                  ? field.Width
                                  : null;

                                let formItemDisplayInNewLine =
                                  isNotNullAndUndefined(field.DisplayInNewLine)
                                    ? field.DisplayInNewLine
                                    : false;

                                return (
                                  <Fragment key={field.UID + "_" + index}>
                                    <RAFAttributesContext.Consumer>
                                      {({ queryAttributes }) => {
                                        return (
                                          <>
                                            {queryAttributes && (
                                              <>
                                                <RAFObjectContext.Consumer>
                                                  {({
                                                    rafObject /*saveObject*/,
                                                  }) => {
                                                    return (
                                                      <>
                                                        <div
                                                          id={
                                                            "formItemDiv_" +
                                                            field.UID
                                                          }
                                                          style={{
                                                            width:
                                                              isNotNullAndUndefined(
                                                                formItemWidth
                                                              ) &&
                                                              !formItemDisplayInNewLine
                                                                ? formItemWidth
                                                                : "100%",
                                                          }}
                                                        >
                                                          {getDetailsElement(
                                                            rafObject,
                                                            queryAttributes,
                                                            field.UID,
                                                            field.Visible,
                                                            field.ShowLabel,
                                                            field.NavigationEnabled,
                                                            (
                                                              relatedEntities,
                                                              refUID
                                                            ) =>
                                                              onNavigationClicked(
                                                                relatedEntities,
                                                                refUID
                                                              ),
                                                            props.type,
                                                            props.profileCard,
                                                            field.DisplayName
                                                          )}
                                                        </div>
                                                        {props.profileCard &&
                                                        index + 1 !==
                                                          column.Components
                                                            .length ? (
                                                          <div className="border-top mb-3"></div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    );
                                                  }}
                                                </RAFObjectContext.Consumer>
                                              </>
                                            )}
                                          </>
                                        );
                                      }}
                                    </RAFAttributesContext.Consumer>
                                  </Fragment>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              );
            })
          ) : (
            <></>
          )}
          {tagComponent}
        </div>
      );
    }
    //  else if (isNotNullAndUndefined(defaultPageLayout)) {
    //   return (
    //     <div className="p-3 pb-2 pt-1">
    //       {isNotNullAndUndefined(defalultFormComponents) &&
    //       defalultFormComponents.length > 0 ? (
    //         defalultFormComponents.map((section, index) => {
    //           return (
    //             <div
    //               key={section.UID + "_" + index}
    //               className="row gx-2 align-items-baseline summary-section-outer customSummary label-left-align"
    //             >
    //               {isNotNullAndUndefined(section.Components) &&
    //               section.Components.length > 0 ? (
    //                 section.Components.map((column, index) => {
    //                   return (
    //                     <div
    //                       className={
    //                         section.Components.length === 1
    //                           ? "col"
    //                           : section.Components.length === 2
    //                           ? "col-md-6"
    //                           : section.Components.length === 3
    //                           ? "col-md-4"
    //                           : "col-md-3"
    //                       }
    //                       key={column.UID + "_" + index}
    //                     >
    //                       <div className="w-100 h-100 d-flex flex-column justify-content-center">
    //                         {isNotNullAndUndefined(column.Components) &&
    //                           column.Components.map((field, index) => {
    //                             return (
    //                               <Fragment key={field.UID + "_" + index}>
    //                                 <RAFAttributesContext.Consumer>
    //                                   {({ queryAttributes }) => {
    //                                     return (
    //                                       <>
    //                                         {queryAttributes && (
    //                                           <>
    //                                             <RAFObjectContext.Consumer>
    //                                               {({
    //                                                 rafObject /*saveObject*/,
    //                                               }) => {
    //                                                 return (
    //                                                   <>
    //                                                     {getDetailsElement(
    //                                                       rafObject,
    //                                                       queryAttributes,
    //                                                       field.UID,
    //                                                       field.Visible,
    //                                                       field.ShowLabel,
    //                                                       field.NavigationEnabled,
    //                                                       (
    //                                                         relatedEntities,
    //                                                         refUID
    //                                                       ) =>
    //                                                         onNavigationClicked(
    //                                                           relatedEntities,
    //                                                           refUID
    //                                                         )
    //                                                     )}
    //                                                   </>
    //                                                 );
    //                                               }}
    //                                             </RAFObjectContext.Consumer>
    //                                           </>
    //                                         )}
    //                                       </>
    //                                     );
    //                                   }}
    //                                 </RAFAttributesContext.Consumer>
    //                               </Fragment>
    //                             );
    //                           })}
    //                       </div>
    //                     </div>
    //                   );
    //                 })
    //               ) : (
    //                 <></>
    //               )}
    //             </div>
    //           );
    //         })
    //       ) : (
    //         <></>
    //       )}
    //       {tagComponent}
    //     </div>
    //   );
    // }
  } else if (isNotNullAndUndefined(defaultFormComponent)) {
    return (
      <div className="p-3 pb-2 pt-1">
        <div className="row gx-2 align-items-baseline summary-section-outer customSummary label-left-align">
          {isNotNullAndUndefined(defaultFormComponent.Components) &&
          defaultFormComponent.Components.length > 0 ? (
            defaultFormComponent.Components.map((column, index) => {
              return (
                <div
                  className={
                    defaultFormComponent.Components.length === 1
                      ? "col"
                      : defaultFormComponent.Components.length === 2
                      ? "col-md-6"
                      : defaultFormComponent.Components.length === 3
                      ? "col-md-4"
                      : "col-md-3"
                  }
                  key={column.UID + "_" + index}
                >
                  <div className="w-100 h-100 d-flex flex-column justify-content-center">
                    {isNotNullAndUndefined(column.Components) &&
                      column.Components.map((field, index) => {
                        return (
                          <Fragment key={field.UID + "_" + index}>
                            <RAFObjectContext.Consumer>
                              {({ rafObject /*saveObject*/ }) => {
                                return (
                                  <div id={"formItemDiv_" + field.UID}>
                                    {getDetailsElement(
                                      rafObject,
                                      queryAttributes,
                                      field.UID,
                                      field.Visible,
                                      true,
                                      field.DataType === RAFDataType.Lookup,
                                      (relatedEntities, refUID) =>
                                        onNavigationClicked(
                                          relatedEntities,
                                          refUID
                                        )
                                    )}
                                  </div>
                                );
                              }}
                            </RAFObjectContext.Consumer>
                          </Fragment>
                        );
                      })}
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
          {tagComponent}
        </div>
      </div>
    );
  } else {
    return (
      <div className="p-3 pb-2 pt-1">
        <div className="row g-3 flex-nowrap">{defaultComponent}</div>
      </div>
    );
  }
}
